import {
	ContextualMenuItemType,
	IButtonProps,
	IContextualMenuItem,
	IContextualMenuProps,
	Image,
	mergeStyleSets,
	Persona,
	PersonaSize,
	PrimaryButton,
	Stack,
	StackItem,
	Text,
} from '@fluentui/react';
import MastLogo from 'Assets/MastLogo.png';
import SHLogo from 'Assets/SHLogo.svg';
import SILogo from 'Assets/SILogo.svg';
import SSLogo from 'Assets/SSLogo.svg';
import { ComponentScreenshot } from 'Components/ComponentScreenshot/ComponentScreenshot';
import LinkButtonPanel from 'Components/Navigation/LinkButtonPanel';
import {
	getHeaderClassNames,
	headerTokens,
	profileIconTokens,
	screenshotIconStyle,
	screenShotOverrideStyles,
} from 'Components/Page/Header.styles';
import { IHeaderProps } from 'Components/Page/Header.types';
import { useCoreDataContext } from 'Context/CoreDataContext/CoreDataContext';
import {
	useGlobalFilters,
	useGlobalFiltersDispatch,
} from 'Context/GlobalFiltersContext/GlobalFiltersContext';
import { useThemeContext } from 'Context/ThemeContext/ThemeContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAccount, LogOut } from 'Utils/MsalConfig';
import { TelemetryService } from 'Utils/TelemetryService';

const linkButtons: IButtonProps[] = [
	{
		href: 'https://aka.ms/mast/',
		iconProps: { imageProps: { src: MastLogo, height: 16 } },
		text: 'MAST',
	},
	{
		href: 'https://aka.ms/si',
		text: 'Service Insights',
		iconProps: { imageProps: { src: SILogo, height: 16 } },
	},
	{
		href: 'https://aka.ms/commercelivesite',
		iconProps: { iconName: 'PowerBILogo16' },
		text: 'C+E Livesite',
	},
	{
		href: 'http://aka.ms/cemsrlivesite',
		iconProps: { iconName: 'PowerBILogo16' },
		text: 'Monthly Service Review',
	},
	{
		href: 'https://aka.ms/cefunlist',
		iconProps: { iconName: 'PowerBILogo16' },
		text: 'Outage Scorecard',
	},
	{
		href: 'https://aka.ms/cerepairsdue',
		iconProps: { iconName: 'PowerBILogo16' },
		text: 'Repair Items',
	},
	{
		href: 'https://aka.ms/cecertinventory',
		iconProps: { iconName: 'PowerBILogo16' },
		text: 'Cert. Inventory',
	},
	{
		href: 'https://aka.ms/commercestatus',
		iconProps: { imageProps: { src: SILogo, height: 16 } },
		text: 'Commerce Status',
	},
];

function Header({ styles, className, fullPageRef, scrollRef }: IHeaderProps): JSX.Element {
	const location = useLocation();
	const theme = useThemeContext();

	const { emMetaData, userMetadata } = useCoreDataContext();
	const { engineeringManager, owner } = useGlobalFilters();
	const globalFiltersDispatch = useGlobalFiltersDispatch();

	const [disableFullPageScreenshot, setDisableFullPageScreenshot] = useState(false);

	const { subComponentStyles, headerStackStyles } = getHeaderClassNames(styles, {
		theme: theme,
		className,
	});

	const account = getAccount();

	/** Props preparation for header menus. */
	const ems: IContextualMenuProps = useMemo(() => {
		const all: IContextualMenuItem = {
			key: 'none',
			text: 'C + E (All)',
			ariaLabel: 'Commerce and Ecosystem',
			onClick: () => /*ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
				item?: IContextualMenuItem*/ {
				globalFiltersDispatch({
					type: 'set engineering manager',
					engineeringManager: null,
				});
				globalFiltersDispatch({ type: 'set servicegroup', serviceGroup: null });
			},
		};

		const temp: IContextualMenuProps = {
			items: [all],
			styles: subComponentStyles.menuStyles,
		};

		temp.items = temp.items.concat(
			Object.keys(emMetaData).filter((emKey) => emMetaData[emKey].TeamShortName != null).map((emKey) => ({
				key: emKey,
				iconProps: {
					iconName: userMetadata.EngineeringManagerAlias === emKey ? 'TeamFavorite' : '',
				},
				text: emMetaData[emKey].TeamShortName + ' (' + emMetaData[emKey].Name + ')',
				id: emKey,
				'data-cy': 'cy-globalManagerFilter',
				onClick:
					() => /*ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
					item?: IContextualMenuItem*/ {
						globalFiltersDispatch({
							type: 'set engineering manager',
							engineeringManager: emKey,
						});
						if (location.pathname === '/gaps')
							globalFiltersDispatch({
								type: 'set servicegroup',
								serviceGroup: emMetaData[emKey].TeamShortName,
							});
						if (!emMetaData[emKey]?.Owners?.has(owner as string)) {
							globalFiltersDispatch({ type: 'set owner', owner: null });
						}
					},
			}))
		);

		return temp;
	}, [
		emMetaData,
		globalFiltersDispatch,
		location.pathname,
		owner,
		subComponentStyles.menuStyles,
		userMetadata.EngineeringManagerAlias,
	]);

	/** Check to see if we should show the screenshot icon */
	const checkScrollTop = useCallback(
		(evt) => {
			const isStickyAbove = evt?.target?.parentElement?.firstElementChild.offsetHeight;
			if (!disableFullPageScreenshot && isStickyAbove) {
				setDisableFullPageScreenshot(true);
			} else if (disableFullPageScreenshot && !isStickyAbove) {
				setDisableFullPageScreenshot(false);
			}
		},
		[disableFullPageScreenshot]
	);

	/** Add event listener */
	useEffect(() => {
		let tempRef: Element | null = null;
		if (scrollRef && scrollRef.current) {
			tempRef = scrollRef.current.children[0].children[1];
			tempRef.addEventListener('scroll', checkScrollTop);
		}
		return () => {
			if (tempRef) tempRef.removeEventListener('scroll', checkScrollTop);
		};
	}, [scrollRef, checkScrollTop]);

	const logo = (process.env.REACT_APP_Env ?? '').toLowerCase() === '_aircapi' ? SSLogo : SHLogo;
	return (
		<Stack
			horizontal
			verticalAlign="center"
			horizontalAlign="space-between"
			grow
			className={ headerStackStyles }
		>
			<Stack horizontal verticalAlign="center">
				<LinkButtonPanel linkButtons={ linkButtons } panelTitle="Apps" />
				<Image
					src={ logo }
					alt="Service Insights"
					height={ 30 } /*className={ brandLink } */
				/>
				{/* </Link> */ }
			</Stack>
			<Stack verticalAlign="center" horizontal>
				<ComponentScreenshot
					disabled={ disableFullPageScreenshot }
					componentDivRef={ fullPageRef }
					screenshotTitle={
						!disableFullPageScreenshot
							? 'Full Page Screenshot'
							: 'Scroll above table to enable full page screenshot'
					}
					buttonStyles={ mergeStyleSets(
						subComponentStyles.primaryButtonStyles({}),
						screenShotOverrideStyles({ theme })
					) }
					iconStyle={ screenshotIconStyle(disableFullPageScreenshot, theme) }
					beforeScreenshot={ () => {
						TelemetryService.trackEvent('Screenshot', { target: 'Full Page' });
					} }
				/>
				<PrimaryButton
					menuProps={ ems }
					data-cy="cy-managerDD"
					iconProps={ { iconName: 'Contact' } }
					text={
						engineeringManager && emMetaData[engineeringManager]
							? emMetaData[engineeringManager].TeamShortName +
							' (' +
							emMetaData[engineeringManager].Name +
							')'
							: 'C + E (All)'
					}
					split={ false }
					styles={ subComponentStyles.primaryButtonStyles({}) }
				></PrimaryButton>
				<PrimaryButton
					menuProps={ getProfileOptions(userMetadata.ProfileImage, account.name ?? '', account.username ?? '') }
					split={ false }
					styles={ subComponentStyles.primaryButtonStyles({}) }
				>
					<Persona
						imageUrl={ userMetadata.ProfileImage }
						text={ account.name }
						size={ PersonaSize.size24 }
						hidePersonaDetails
						aria-label="Persona Settings"
						styles={ subComponentStyles.profilestyles }
					/>
				</PrimaryButton>
			</Stack>
		</Stack>
	);
}

/** Get menuItems for user profile menu. */
const getProfileOptions = (profileImage: string, userFullName: string, userEmail: string): IContextualMenuProps => {
	const items: IContextualMenuItem[] = [
		{
			key: 'Persona',
			iconOnly: true,
			text: userFullName,
			onRender: () => (
				<Stack horizontal>
					<StackItem tokens={ profileIconTokens }>
						<Persona
							imageUrl={ profileImage }
							text={ userFullName }
							size={ PersonaSize.size32 }
							hidePersonaDetails
						/>
					</StackItem>
					<Stack verticalFill tokens={ headerTokens }>
						<Text>{ userFullName }</Text>

						<Text>{ userEmail }</Text>
					</Stack>
				</Stack>
			),
		},
		{
			key: 'divider_1',
			itemType: ContextualMenuItemType.Divider,
		},
		{
			key: 'Help',
			subMenuProps: {
				items: [
					{
						key: 'Wiki',
						text: 'Wiki',
						title: 'Wiki',
						iconProps: {
							iconName: 'Globe',
						},
						href: '/help/Docs/selfservewiki.html',
						target: '_blank',
					},
					{
						key: 'Contact Us',
						text: 'Contact Us',
						title: 'Contact Us',
						iconProps: {
							iconName: 'Mail',
						},
						href: 'mailto:shsupport@microsoft.com',
					},
					{
						key: 'Teams Channel',
						text: 'Teams Channel',
						title: 'Teams Channel',
						iconProps: {
							iconName: 'People',
						},
						href: 'https://teams.microsoft.com/l/channel/19%3a9defc3e13ab1434ea5661c61083b34cc%40thread.tacv2/General?groupId=34fcbb55-bdc6-4755-940f-2188b9faad9b&tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47',
						target: '_blank',
					},
					{
						key: 'Scenario Health General Contractors',
						text: 'Scenario Health General Contractors',
						title: 'Scenario Health General Contractors',
						iconProps: {
							iconName: 'People',
						},
						href: 'http://aka.ms/shgc',
						target: '_blank',
					},
					{
						key: 'Scenario Health Training',
						text: 'Scenario Health Training',
						title: 'Scenario Health Training',
						iconProps: {
							iconName: 'People',
						},
						href: 'https://microsoft.sharepoint.com/:v:/t/CommerceAvailabilityReporting/Eaf2wdsbAKlDnjXAoatcNBoBfHwIa7F8JpnyivUYIhMHoQ?e=9eS11A&nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZy1MaW5rIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXcifX0%3D&xsdata=MDV8MDJ8ZGFncmF3YWxAbWljcm9zb2Z0LmNvbXxkMzdhMjViZTA2OTA0M2IwYjc3NjA4ZGMzMzE0NjM0MHw3MmY5ODhiZjg2ZjE0MWFmOTFhYjJkN2NkMDExZGI0N3wxfDB8NjM4NDQxNDA5ODc3NDQ2MzQ2fFVua25vd258VFdGcGJHWnNiM2Q4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazFoYVd3aUxDSlhWQ0k2TW4wPXwwfHx8&sdata=UHFYbjlZblpDcG9NVEpMK3N5NDBTRnNsMkZ3WnFBSW1qb0lvY1dDcmRZWT0%3D&referrer=StreamWebApp%2EWeb&referrerScenario=AddressBarCopiedShareExpTreatment%2Eview',
						target: '_blank',
					},
				],
			},
			iconOnly: true,
			iconProps: {
				iconName: 'Help',
			},
			text: 'Help',
		},
		{
			key: 'divider_1',
			itemType: ContextualMenuItemType.Divider,
		},
		{
			key: 'logout',
			iconOnly: true,
			iconProps: {
				iconName: 'Lock',
			},
			text: 'Sign out',
			onClick: LogOut,
		},
	];
	return { items };
};

export default Header;
