import {
	Action,
	AggregateAction,
	AggregateDispatch,
	AggregateFilters,
	AggregateState,
	IAggregateCache,
	IAggregateKeyProp,
	IFinalAggregateCache,
	IFinalFullAggregateCache,
	IFullAggregateCache,
} from 'Context/AggregateContext/AggregateContext.types';
import { IGlobalFiltersState } from 'Context/GlobalFiltersContext/GlobalFiltersContext.types';
import {
	LobsIterator,
	LobToCustomerKeysMap
} from 'DataModels/CoreDataModels';
import {
	AggregateLob,
	Aggregates,
	AggregateScenario,
	MajorScenarioMetaData,
	MetaData,
	MinorScenarioMetaData,
	ModifiedApiDataMap,
	ModifiedCoreApiData,
	ModifiedGraduatedScenario,
	ModifiedReliabilityModel,
	MonthlyLatencyModel,
	ScenarioGroupMetaData
} from 'DataModels/CoreDataModels.types';
import { LobEnum, Nines } from 'DataModels/Global.types';
import { ModifiedIcmItem } from 'DataModels/LinkedItems.types';
import deepAssign from 'object-assign-deep';
import React, { createContext, useReducer } from 'react';
import { avgValue, floatAdd, floatSubtract, sumValue } from 'Utils/Util';

const initialState: AggregateState = {
	aggregateCache: {},
	currentAggregateCache: {} as IFinalAggregateCache,
	status: 'empty',
};

const DataProviderContext = createContext<AggregateState | undefined>(undefined);
const DataProviderDispatch = createContext<AggregateDispatch | undefined>(undefined);

function aggregateReducer(prevState: AggregateState, action: Action): AggregateState {
	switch (action.type) {
		case 'generate aggregates': {
			generateAggregates(action);
			return { ...prevState, status: 'in progress' };
		}
		case 'aggregates generated': {
			return {
				...prevState,
				aggregateCache: action.aggregates,
				currentAggregateCache: action.aggregates[action.aggrKey],
				status: 'success',
			};
		}
		default:
			throw new Error(`Unhandled action: ${ (action as Action).type }`);
	}
}

function generateAggregates({
	graduatedScenarios,
	aggrKey,
	aggrKeyProps,
	currentPage,
	dispatch,
	filteredData, //6 months data =>  mapping: date - list of scenarios with reliability data (total/success/failure/latency/monthlyLatency)
	filters,
	majorScenariosList,
	minorScenariosList, // scenario metadata (no reliability data)
	scenarioGroupList, //mapping: service group name - list of scenario Ids
}: AggregateAction) {
	const aggregateFilters: AggregateFilters = {
		customTargets: true,
		inSlo: 'all',
		scenarioGroup: '',
		source: 'all',
		groupBy: 'scenario',
		hiddenScenarioGroups: [],
		...filters,
	};

	originalGenerateAggregates(
		filteredData,
		aggrKeyProps,
		majorScenariosList,
		minorScenariosList,
		scenarioGroupList,
		currentPage,
		graduatedScenarios,
		aggregateFilters,
		(aggregates) =>
			dispatch({ type: 'aggregates generated', aggregates: aggregates, aggrKey: aggrKey })
	);
}

function AggregateProvider({ children }: React.PropsWithChildren<unknown>): JSX.Element {
	const [state, dispatch] = useReducer(aggregateReducer, initialState);
	return (
		<DataProviderContext.Provider value={ state }>
			<DataProviderDispatch.Provider value={ dispatch }>
				{ children }
			</DataProviderDispatch.Provider>
		</DataProviderContext.Provider>
	);
}

function useAggregateContext(): AggregateState {
	const context = React.useContext(DataProviderContext);
	if (context === undefined) {
		throw new Error('useAggregateContext must be used within a AggregateProvider ');
	}
	return context;
}

function useAggregateDispatch(): AggregateDispatch {
	const context = React.useContext(DataProviderDispatch);
	if (context === undefined) {
		throw new Error('useAggregateDispatch must be used within a AggregateProvider ');
	}

	return context;
}

function useAggregateProvider() {
	return {
		aggregateData: useAggregateContext(),
		aggregateDispatch: useAggregateDispatch(),
	};
}

AggregateProvider.useAggregateProvider = useAggregateProvider;

export { AggregateProvider, filterData, useAggregateContext, useAggregateDispatch };

const generateAggregateLobTemplate = (): AggregateLob => ({
	GraduatedScenario: null,
	FullData: {},
	HasData: false,
	LinkedIcmItems: new Set<ModifiedIcmItem>(),
	SuggestedIcmItems: new Set<ModifiedIcmItem>(),
	Latency: [],
	MonthlyLatency: null,
	MinorsInSlo: 0,
	MinorsNotInSlo: 0,
	Reliability: [],
	Requests: null,
	Success: null,
	DataSource: new Set<string>(),
	DailyCumulativeBudget: [],
	Next9DailyCumulativeBudget: [],
	ErrorBudgetRemaining: null,
	Next9ErrorBudgetRemaining: null,
});

/**
 * Create Aggregate Lob templates for every possible AggregateLob in the expected set of aggegate timeframes
 * @param {IAggregateKeyProp[]} aggrKeyProps the expected set of aggregate caches
 * @param {ScenarioGroupMetaData[]} scenarioGroupList scenario group metadata
 * @param {MajorScenarioMetaData[]} majorScenariosList major scenario metadata
 * @param {MinorScenarioMetaData[]} minorScenariosList minor scenario metadata
 * @param {AggregateFilters} aggregateFilters global filters
 * @param {string} currentPage current page for the keyword filter
 * @returns {IFullAggregateCache} An empty Aggregate cache
 */
const createFullAggregateDefaults = (
	aggrKeyProps: IAggregateKeyProp[],
	scenarioGroupList: ScenarioGroupMetaData[],
	majorScenariosList: MajorScenarioMetaData[],
	minorScenariosList: MinorScenarioMetaData[],
	aggregateFilters: AggregateFilters,
	currentPage: string
): IFullAggregateCache => {
	const { source } = aggregateFilters;
	const megaAggregates: IFullAggregateCache = {};

	const tempAllScenarios = filterScenarios(
		majorScenariosList.concat(minorScenariosList),
		aggregateFilters,
		currentPage
	) as MetaData[];
	const tempMajorScenariosList: MajorScenarioMetaData[] = [];
	const tempMinorScenariosList: MinorScenarioMetaData[] = tempAllScenarios.reduce(
		(arr: MinorScenarioMetaData[], scenario: MajorScenarioMetaData | MinorScenarioMetaData) => {
			if (scenario.Parent1Id === 0)
				tempMajorScenariosList.push(scenario as MajorScenarioMetaData);
			else arr.push(scenario as MinorScenarioMetaData);
			return arr;
		},
		[]
	);

	/////////////////
	//
	// Generate Major Scenario  templates
	//
	/////////////////
	for (const aggrKeyProp of aggrKeyProps) {
		const tempAggr: IAggregateCache = {
			overall: {} as Aggregates,
			// major: {} as Record<number, AggregateScenario>,
			minor: {} as Record<number, AggregateScenario>,
			scenarioGroups: {} as Record<string, AggregateScenario>,
		};
		megaAggregates[aggrKeyProp.aggrKey] = tempAggr;
	}

	/////////////////
	//
	// Generate Major Scenario LOB templates
	//
	/////////////////
	for (const tempAggr of Object.values(megaAggregates)) {
		/////////////////
		//
		// Generate Minor Scenario  templates
		//
		/////////////////
		tempMinorScenariosList.forEach((scenario) => {
			(tempAggr.minor[scenario.Id] as Partial<AggregateScenario>) = deepAssign({}, scenario);

			const minorScenario = tempAggr.minor[scenario.Id];

			LobsIterator.forEach(
				(avaConstant) => (minorScenario[avaConstant] = generateAggregateLobTemplate())
			);

			minorScenario.IsCovered = true;
			minorScenario.IsNext9 = false;
			if (source !== 'all') minorScenario.Source = source;
		});

		/////////////////
		//
		// Generate scenario group templates
		//
		/////////////////
		scenarioGroupList.forEach((sg) => {
			(tempAggr.scenarioGroups[sg.Name] as Partial<AggregateScenario>) = deepAssign({}, sg);
			const scenarioGroup = tempAggr.scenarioGroups[sg.Name];

			LobsIterator.forEach((lob) => (scenarioGroup[lob] = generateAggregateLobTemplate()));
		});

		LobsIterator.forEach(
			(avaConstant) => (tempAggr.overall[avaConstant] = generateAggregateLobTemplate())
		);
	}
	/////////////////
	//
	// Create Map to next/previous aggregates
	//
	/////////////////
	for (const { aggrKey, nextAggrKey, previousAggrKey } of aggrKeyProps) {
		//Previous Aggregates
		if (previousAggrKey && megaAggregates[previousAggrKey]) {
			megaAggregates[aggrKey].previousAggregate = megaAggregates[previousAggrKey];

			tempMinorScenariosList.forEach(
				(minor) =>
				(megaAggregates[aggrKey].minor[minor.Id].PreviousAggregate =
					megaAggregates[previousAggrKey].minor[minor.Id])
			);

			scenarioGroupList.forEach((scenarioGroup) => {
				megaAggregates[aggrKey].scenarioGroups[scenarioGroup.Name].PreviousAggregate =
					megaAggregates[previousAggrKey].scenarioGroups[scenarioGroup.Name];
			});

			LobsIterator.forEach((avaConstant) => {
				tempMinorScenariosList.forEach(({ Id }) => {
					megaAggregates[aggrKey].minor[Id][avaConstant].PreviousAggregate =
						megaAggregates[previousAggrKey].minor[Id][avaConstant];
				});

				scenarioGroupList.forEach(({ Name }) => {
					megaAggregates[aggrKey].scenarioGroups[Name][avaConstant].PreviousAggregate =
						megaAggregates[previousAggrKey].scenarioGroups[Name][avaConstant];
				});

				megaAggregates[aggrKey].overall[avaConstant].PreviousAggregate =
					megaAggregates[previousAggrKey].overall[avaConstant];
			});
		}

		//Next Aggregates
		if (nextAggrKey && megaAggregates[nextAggrKey]) {
			megaAggregates[aggrKey].nextAggregate = megaAggregates[nextAggrKey];

			tempMinorScenariosList.forEach(
				(minor) =>
				(megaAggregates[aggrKey].minor[minor.Id].NextAggregate =
					megaAggregates[nextAggrKey].minor[minor.Id])
			);

			scenarioGroupList.forEach((scenarioGroup) => {
				megaAggregates[aggrKey].scenarioGroups[scenarioGroup.Name].NextAggregate =
					megaAggregates[nextAggrKey].scenarioGroups[scenarioGroup.Name];
			});

			LobsIterator.forEach((avaConstant) => {
				tempMinorScenariosList.forEach(({ Id }) => {
					megaAggregates[aggrKey].minor[Id][avaConstant].NextAggregate =
						megaAggregates[nextAggrKey].minor[Id][avaConstant];
				});

				scenarioGroupList.forEach(({ Name }) => {
					megaAggregates[aggrKey].scenarioGroups[Name][avaConstant].NextAggregate =
						megaAggregates[nextAggrKey].scenarioGroups[Name][avaConstant];
				});

				megaAggregates[aggrKey].overall[avaConstant].NextAggregate =
					megaAggregates[nextAggrKey].overall[avaConstant];
			});
		}
	}
	return megaAggregates;
};

//TODO: remove this and fix complexity
/* eslint-disable complexity */
function originalGenerateAggregates(
	filteredData: ModifiedApiDataMap,
	aggrKeyProps: IAggregateKeyProp[],
	majorScenariosList: MajorScenarioMetaData[],
	minorScenariosList: MinorScenarioMetaData[],
	scenarioGroupList: ScenarioGroupMetaData[],
	currentPage: string,
	graduatedScenarios: ModifiedGraduatedScenario[],
	aggregateFilters: AggregateFilters,
	callback: (fullAggregates: IFinalFullAggregateCache) => void
): void {
	// const startPerf = performance.now()

	const fullAggregates = createFullAggregateDefaults(
		aggrKeyProps,
		scenarioGroupList,
		majorScenariosList,
		minorScenariosList,
		aggregateFilters,
		currentPage
	);

	const { benchmark, inSlo, customTargets /*source*/ } = aggregateFilters;

	const showRed = inSlo === 'no' || inSlo === 'all';
	const showGreen = inSlo === 'yes' || inSlo === 'all';

	const apiDataEntries = Object.entries<ModifiedCoreApiData[]>(filteredData);

	//For each day
	apiDataEntries.forEach(([dateKey, dailyScenarios]) => {
		//For each Minor Scenario
		dailyScenarios.forEach((data: ModifiedCoreApiData) => {
			LobsIterator.forEach((avaConstant) => {
				if (!data[avaConstant])
					data[avaConstant] = {
						Reliability: null,
						MonthlyLatency: null,
						Requests: null,
						Success: null,
						HasData: false,
						GraduatedScenario: null,
						LinkedIcmItems: [],
						SuggestedIcmItems: [],
						Latency: null,
						FullData: {},
						DailyCumulativeBudget: [],
						Next9DailyCumulativeBudget: [],
						ErrorBudgetRemaining: null,
						Next9ErrorBudgetRemaining: null
					};
			});

			// Minor Scenario
			if (data.Parent1Id !== 0) {
				// if parentid doesnt exist then don't consider row in computation (case where parentRow is l2 and child is l1)

				for (const agp of aggrKeyProps) {
					if (dateKey < agp.startDate || dateKey >= agp.endDate) continue;
					const isEntireSingleMonth = CheckIfEntireSingleMonth(agp.startDate, agp.endDate);
					const currentAgg = fullAggregates[agp.aggrKey];
					const tempAggr = currentAgg.overall;
					const scenarioGroups = currentAgg.scenarioGroups;
					let row = currentAgg.minor[data.Id as number];
					const sg = data.Parent2Id || data.Parent2Name || 'Unspecified';

					//We should never hit this with the new api (causes bugs when filtering by keyword/owner/etc)
					//EXCEPT for minor scenarios that have an unspecified major scenario
					if (!row && data.Id > 1000) {
						/////////////////
						//
						// TODO: Don't render unspecified at all
						//
						/////////////////

						row = deepAssign({}, data);
						row.Id = Number(row.Id);
						currentAgg.minor[row.Id] = row;
						if (!row.Parent2Name) row.Parent2Name = 'Unspecified';
						if (!row.IsNext9) row.IsNext9 = !!data.IsNext9;

						row['Overall'] = {
							Reliability: [],
							Requests: null,
							Success: null,
							HasData: false,
							// InSlo: 0,
							// NotInSlo: 0,
							MinorsInSlo: 0,
							MinorsNotInSlo: 0,
							GraduatedScenario: null,
							LinkedIcmItems: new Set<ModifiedIcmItem>(),
							SuggestedIcmItems: new Set<ModifiedIcmItem>(),
							Latency: [],
							MonthlyLatency: null,
							FullData: {},
							DailyCumulativeBudget: [],
							Next9DailyCumulativeBudget: [],
							ErrorBudgetRemaining: null,
							Next9ErrorBudgetRemaining: null,
						};

						LobsIterator.slice(0, LobsIterator.length - 1).forEach((avaConstant) => {
							// Map Previous/Next aggregates if they exist.
							if (currentAgg.previousAggregate) {
								const previousRow =
									currentAgg.previousAggregate.minor[row.Id as number];
								if (previousRow) {
									row.PreviousAggregate = previousRow;
									row[avaConstant].PreviousAggregate = previousRow[avaConstant];

									if (!previousRow.NextAggregate) previousRow.NextAggregate = row;
									previousRow[avaConstant].NextAggregate = row[avaConstant];
								}
							}
							if (currentAgg.nextAggregate) {
								const nextRow = currentAgg.nextAggregate.minor[row.Id as number];
								if (nextRow) {
									row.NextAggregate = nextRow;
									row[avaConstant].NextAggregate = nextRow[avaConstant];

									if (!nextRow.PreviousAggregate) nextRow.PreviousAggregate = row;
									nextRow[avaConstant].PreviousAggregate = row[avaConstant];
								}
							}

							const rowLob = row[avaConstant];
							const reliab = rowLob.Reliability as number;
							const dataLob = data[avaConstant] as ModifiedReliabilityModel;
							let aboveTarget;

							if (customTargets) {
								aboveTarget = row[avaConstant].Target
									? reliab >= (row[avaConstant].Target as number)
									: reliab >= benchmark;
							} else aboveTarget = reliab && reliab >= benchmark;

							rowLob.HasData = !!(reliab || reliab === 0);

							if (
								(reliab || reliab === 0) &&
								((showGreen && aboveTarget) || (showRed && !aboveTarget))
							) {
								(tempAggr[avaConstant].Reliability as number[]).push(reliab);

								//New EB Calculation
								if (reliab) {
									(tempAggr[avaConstant].DailyCumulativeBudget as number[]).push(
										floatSubtract(
											100.0,
											floatSubtract(
												tempAggr[avaConstant].Target || benchmark,
												floatSubtract(100.0, reliab)
											)
										)
									);
									(tempAggr[avaConstant].Next9DailyCumulativeBudget as number[]).push(
										floatSubtract(
											100.0,
											floatSubtract(
												tempAggr[avaConstant].Next9Target || benchmark,
												floatSubtract(100.0, reliab)
											)
										)
									);
								}
								if (!tempAggr[avaConstant].FullData[dateKey])
									tempAggr[avaConstant].FullData[dateKey] = [];
								tempAggr[avaConstant].FullData[dateKey].push(dataLob);

								(tempAggr['Overall'].Reliability as number[]).push(reliab);

								//New EB Calculation
								if (reliab) {
									(tempAggr['Overall'].DailyCumulativeBudget as number[]).push(
										floatSubtract(
											100.0,
											floatSubtract(
												tempAggr['Overall'].Target || benchmark,
												floatSubtract(100.0, reliab)
											)
										)
									);

									(tempAggr['Overall'].Next9DailyCumulativeBudget as number[]).push(
										floatSubtract(
											100.0,
											floatSubtract(
												tempAggr['Overall'].Next9Target || benchmark,
												floatSubtract(100.0, reliab)
											)
										)
									);
								}
								if (!tempAggr['Overall'].FullData[dateKey])
									tempAggr['Overall'].FullData[dateKey] = [];
								tempAggr['Overall'].FullData[dateKey].push(dataLob);

								if (sg && scenarioGroups[sg]) {
									(scenarioGroups[sg][avaConstant].Reliability as number[]).push(
										reliab
									);

									//New EB Calculation
									if (reliab) {
										(
											scenarioGroups[sg][avaConstant]
												.DailyCumulativeBudget as number[]
										).push(
											floatSubtract(
												100.0,
												floatSubtract(
													scenarioGroups[sg][avaConstant].Target ||
													benchmark,
													floatSubtract(100.0, reliab)
												)
											)
										);
										(
											scenarioGroups[sg][avaConstant]
												.Next9DailyCumulativeBudget as number[]
										).push(
											floatSubtract(
												100.0,
												floatSubtract(
													scenarioGroups[sg][avaConstant].Next9Target ||
													benchmark,
													floatSubtract(100.0, reliab)
												)
											)
										);
									}
									if (!scenarioGroups[sg][avaConstant].FullData[dateKey])
										scenarioGroups[sg][avaConstant].FullData[dateKey] = [];
									scenarioGroups[sg][avaConstant].FullData[dateKey].push(dataLob);

									(scenarioGroups[sg]['Overall'].Reliability as number[]).push(
										reliab
									);

									//New EB Calculation
									if (reliab) {
										(
											scenarioGroups[sg]['Overall']
												.DailyCumulativeBudget as number[]
										).push(
											floatSubtract(
												100.0,
												floatSubtract(
													scenarioGroups[sg]['Overall'].Target ||
													benchmark,
													floatSubtract(100.0, reliab)
												)
											)
										);
										(
											scenarioGroups[sg]['Overall']
												.Next9DailyCumulativeBudget as number[]
										).push(
											floatSubtract(
												100.0,
												floatSubtract(
													scenarioGroups[sg]['Overall'].Next9Target ||
													benchmark,
													floatSubtract(100.0, reliab)
												)
											)
										);
									}
									if (!scenarioGroups[sg]['Overall'].FullData[dateKey])
										scenarioGroups[sg]['Overall'].FullData[dateKey] = [];

									scenarioGroups[sg]['Overall'].FullData[dateKey].push(dataLob);
								} else {
									(
										scenarioGroups['Unspecified'][avaConstant]
											.Reliability as number[]
									).push(reliab);
									//New EB Calculation
									if (reliab) {
										(
											scenarioGroups['Unspecified'][avaConstant]
												.DailyCumulativeBudget as number[]
										).push(
											floatSubtract(
												100.0,
												floatSubtract(
													scenarioGroups['Unspecified'][avaConstant]
														.Target || benchmark,
													floatSubtract(100.0, reliab)
												)
											)
										);
										(
											scenarioGroups['Unspecified'][avaConstant]
												.Next9DailyCumulativeBudget as number[]
										).push(
											floatSubtract(
												100.0,
												floatSubtract(
													scenarioGroups['Unspecified'][avaConstant]
														.Next9Target || benchmark,
													floatSubtract(100.0, reliab)
												)
											)
										);
									}
									if (
										!scenarioGroups['Unspecified'][avaConstant].FullData[
										dateKey
										]
									)
										scenarioGroups['Unspecified'][avaConstant].FullData[
											dateKey
										] = [];
									scenarioGroups['Unspecified'][avaConstant].FullData[
										dateKey
									].push(dataLob);

									(
										scenarioGroups['Unspecified']['Overall']
											.Reliability as number[]
									).push(reliab);

									//New EB Calculation
									if (reliab) {
										(
											scenarioGroups['Unspecified']['Overall']
												.DailyCumulativeBudget as number[]
										).push(
											floatSubtract(
												100.0,
												floatSubtract(
													scenarioGroups['Unspecified']['Overall']
														.Target || benchmark,
													floatSubtract(100.0, reliab)
												)
											)
										);
										(
											scenarioGroups['Unspecified']['Overall']
												.Next9DailyCumulativeBudget as number[]
										).push(
											floatSubtract(
												100.0,
												floatSubtract(
													scenarioGroups['Unspecified']['Overall']
														.Next9Target || benchmark,
													floatSubtract(100.0, reliab)
												)
											)
										);
									}
									if (!scenarioGroups['Unspecified']['Overall'].FullData[dateKey])
										scenarioGroups['Unspecified']['Overall'].FullData[dateKey] =
											[];
									scenarioGroups['Unspecified']['Overall'].FullData[dateKey].push(
										dataLob
									);
								}

								// add to Minor overall
								(row['Overall'].Reliability as number[]).push(reliab);

								//New EB Calculation
								if (reliab) {
									(row['Overall'].DailyCumulativeBudget as number[]).push(
										floatSubtract(
											100.0,
											floatSubtract(
												row['Overall'].Target || benchmark,
												floatSubtract(100.0, reliab)
											)
										)
									);
									(row['Overall'].Next9DailyCumulativeBudget as number[]).push(
										floatSubtract(
											100.0,
											floatSubtract(
												row['Overall'].Next9Target || benchmark,
												floatSubtract(100.0, reliab)
											)
										)
									);
								}
								if (!row['Overall'].FullData[dateKey])
									row['Overall'].FullData[dateKey] = [];
								row['Overall'].FullData[dateKey].push(dataLob);

								row[avaConstant].Reliability = [reliab];

								//New EB Calculation
								if (reliab) {
									(row[avaConstant].DailyCumulativeBudget as number[]).push(
										floatSubtract(
											floatSubtract(
												100.0,
												row[avaConstant].Target || benchmark
											),
											floatSubtract(100.0, reliab)
										)
									);
									(row[avaConstant].Next9DailyCumulativeBudget as number[]).push(
										floatSubtract(
											floatSubtract(
												100.0,
												row[avaConstant].Next9Target || benchmark
											),
											floatSubtract(100.0, reliab)
										)
									);
								}
								row[avaConstant].FullData[dateKey].push(dataLob);
							} else {
								row[avaConstant].Reliability = [];
							}

							(tempAggr.Overall.Reliability as number[]).push(
								data[avaConstant].Reliability as number
							);

							const totalRequests = data[avaConstant].Requests;

							if (totalRequests) {
								// add to Minor channel
								row[avaConstant].Requests =
									(row[avaConstant].Requests || 0) + totalRequests;
								// add to Minor overall
								row['Overall'].Requests =
									(row['Overall'].Requests || 0) + totalRequests;

								if (sg && scenarioGroups[sg]) {
									scenarioGroups[sg][avaConstant].Requests =
										(scenarioGroups[sg][avaConstant].Requests || 0) +
										totalRequests;

									scenarioGroups[sg]['Overall'].Requests =
										(scenarioGroups[sg]['Overall'].Requests || 0) +
										totalRequests;
								} else {
									scenarioGroups['Unspecified'][avaConstant].Requests =
										(scenarioGroups['Unspecified'][avaConstant].Requests || 0) +
										totalRequests;

									scenarioGroups['Unspecified']['Overall'].Requests =
										(scenarioGroups['Unspecified']['Overall'].Requests || 0) +
										totalRequests;
								}
							}

							const successRequests =
								data[avaConstant].Success ??
								(totalRequests && reliab && totalRequests * (reliab / 100));

							if (successRequests) {
								// add to Minor channel
								row[avaConstant].Success =
									(row[avaConstant].Success || 0) + successRequests;
								// add to Minor overall
								row['Overall'].Success =
									(row['Overall'].Success || 0) + successRequests;

								if (sg && scenarioGroups[sg]) {
									scenarioGroups[sg][avaConstant].Success =
										(scenarioGroups[sg][avaConstant].Success || 0) +
										successRequests;

									scenarioGroups[sg]['Overall'].Success =
										(scenarioGroups[sg]['Overall'].Success || 0) +
										successRequests;
								} else {
									scenarioGroups['Unspecified'][avaConstant].Success =
										(scenarioGroups['Unspecified'][avaConstant].Success || 0) +
										successRequests;

									scenarioGroups['Unspecified']['Overall'].Success =
										(scenarioGroups['Unspecified']['Overall'].Success || 0) +
										successRequests;
								}
							}

							//Add icm annotations to everything.
							if (
								data[avaConstant].LinkedIcmItems &&
								(data[avaConstant].LinkedIcmItems as ModifiedIcmItem[]).length
							) {
								data[avaConstant].LinkedIcmItems.forEach((icm: ModifiedIcmItem) => {
									(row[avaConstant].LinkedIcmItems as Set<ModifiedIcmItem>).add(
										icm
									);
									(row['Overall'].LinkedIcmItems as Set<ModifiedIcmItem>).add(
										icm
									);

									(
										tempAggr[avaConstant].LinkedIcmItems as Set<ModifiedIcmItem>
									).add(icm);
									(
										tempAggr['Overall'].LinkedIcmItems as Set<ModifiedIcmItem>
									).add(icm);

									if (sg && scenarioGroups[sg]) {
										(
											scenarioGroups[sg][avaConstant]
												.LinkedIcmItems as Set<ModifiedIcmItem>
										).add(icm);
										(
											scenarioGroups[sg]['Overall']
												.LinkedIcmItems as Set<ModifiedIcmItem>
										).add(icm);
									} else {
										(
											scenarioGroups['Unspecified'][avaConstant]
												.LinkedIcmItems as Set<ModifiedIcmItem>
										).add(icm);
										(
											scenarioGroups['Unspecified']['Overall']
												.LinkedIcmItems as Set<ModifiedIcmItem>
										).add(icm);
									}
								});
							}

							if (
								data[avaConstant].SuggestedIcmItems &&
								(data[avaConstant].SuggestedIcmItems as ModifiedIcmItem[]).length
							) {
								data[avaConstant].SuggestedIcmItems.forEach(
									(icm: ModifiedIcmItem) => {
										(
											row[avaConstant]
												.SuggestedIcmItems as Set<ModifiedIcmItem>
										).add(icm);
										(
											row['Overall'].SuggestedIcmItems as Set<ModifiedIcmItem>
										).add(icm);

										(
											tempAggr[avaConstant]
												.SuggestedIcmItems as Set<ModifiedIcmItem>
										).add(icm);
										(
											tempAggr['Overall']
												.SuggestedIcmItems as Set<ModifiedIcmItem>
										).add(icm);

										if (sg && scenarioGroups[sg]) {
											(
												scenarioGroups[sg][avaConstant]
													.SuggestedIcmItems as Set<ModifiedIcmItem>
											).add(icm);
											(
												scenarioGroups[sg]['Overall']
													.SuggestedIcmItems as Set<ModifiedIcmItem>
											).add(icm);
										} else {
											(
												scenarioGroups['Unspecified'][avaConstant]
													.SuggestedIcmItems as Set<ModifiedIcmItem>
											).add(icm);
											(
												scenarioGroups['Unspecified']['Overall']
													.SuggestedIcmItems as Set<ModifiedIcmItem>
											).add(icm);
										}
									}
								);
							}

							//Add Latency to everything
							const latency = data[avaConstant].Latency as number;
							if (latency && typeof latency === 'number') {
								row[avaConstant].Latency = [latency];
								(row['Overall'].Latency as number[]).push(latency);

								(tempAggr[avaConstant].Latency as number[]).push(latency);
								(tempAggr['Overall'].Latency as number[]).push(latency);

								if (sg && scenarioGroups[sg]) {
									(scenarioGroups[sg][avaConstant].Latency as number[]).push(
										latency
									);
									(scenarioGroups[sg]['Overall'].Latency as number[]).push(
										latency
									);
								} else {
									(
										scenarioGroups['Unspecified'][avaConstant]
											.Latency as number[]
									).push(latency);
									(
										scenarioGroups['Unspecified']['Overall'].Latency as number[]
									).push(latency);
								}
								(tempAggr.Overall.Latency as number[]).push(latency);
							}
							const monthlylatency = data[avaConstant].MonthlyLatency as MonthlyLatencyModel;
							if (isEntireSingleMonth && data[avaConstant].Requests != null) {
								row[avaConstant].MonthlyLatency = monthlylatency;
								row['Overall'].MonthlyLatency = monthlylatency;
								tempAggr[avaConstant].MonthlyLatency = monthlylatency;
								tempAggr['Overall'].MonthlyLatency = monthlylatency;
								if (sg && scenarioGroups[sg]) {
									scenarioGroups[sg][avaConstant].MonthlyLatency = monthlylatency;
									scenarioGroups[sg]['Overall'].MonthlyLatency = monthlylatency;
								} else {
									scenarioGroups['Unspecified'][avaConstant].MonthlyLatency = monthlylatency;
									scenarioGroups['Unspecified']['Overall'].MonthlyLatency = monthlylatency;
								}
							}

							if (!row.CustomerKeys) row.CustomerKeys = data.CustomerKeys;
							if (!row.ScenarioKeys) row.ScenarioKeys = data.ScenarioKeys;

							if (
								data[avaConstant].DataSource &&
								(data[avaConstant].DataSource as string[]).length
							) {
								(data[avaConstant].DataSource as string[]).forEach((ds) =>
									(row[avaConstant].DataSource as Set<string>).add(ds)
								);
							}
						});
					} else if (row) {
						if (!row.IsNext9) row.IsNext9 = !!data.IsNext9;
						//Add things that are missing from the Scenarios metadata
						const minorScenarios = currentAgg.minor;

						if (!minorScenarios[row.Id as number].SegmentOwnerAliases) {
							minorScenarios[row.Id as number].SegmentOwnerAliases =
								data.SegmentOwnerAliases ? data.SegmentOwnerAliases : [];
						}

						if (!minorScenarios[row.Id as number].Services) {
							minorScenarios[row.Id as number].Services = new Set<string>();
						}
						if (data.Services) {
							for (const service of data.Services as string[]) {
								(minorScenarios[row.Id as number].Services as Set<string>).add(
									service
								);
							}
						}

						LobsIterator.slice(0, LobsIterator.length - 1).forEach((avaConstant) => {
							if (data[avaConstant].Target)
								row[avaConstant].Target = data[avaConstant].Target; //Add missing custom target

							if (data[avaConstant].Next9Target)
								row[avaConstant].Next9Target = data[avaConstant].Next9Target; //Next nine target

							const reliab = data[avaConstant].Reliability as number;
							const dataLob = data[avaConstant] as ModifiedReliabilityModel;

							if (!minorScenarios[row.Id as number][avaConstant].HasData) {
								minorScenarios[row.Id as number][avaConstant].HasData =
									!!(reliab || reliab === 0) ||
									!!minorScenarios[row.Id as number][avaConstant].HasData;
							}
							if (!minorScenarios[row.Id as number]['Overall'].HasData) {
								minorScenarios[row.Id as number]['Overall'].HasData =
									!!(reliab || reliab === 0) ||
									!!minorScenarios[row.Id as number][avaConstant].HasData;
							}

							if (reliab !== undefined && reliab !== null) {
								let aboveTarget;
								if (customTargets) {
									aboveTarget = row[avaConstant].Target
										? reliab >= (row[avaConstant].Target as number)
										: reliab >= benchmark;
								} else aboveTarget = reliab && reliab >= benchmark;

								if ((showGreen && aboveTarget) || (showRed && !aboveTarget)) {
									// add to Minor channel
									(row[avaConstant].Reliability as number[]).push(reliab);

									//New EB Calculation
									if (reliab !== undefined && reliab !== null) {
										(row[avaConstant].DailyCumulativeBudget as number[]).push(
											floatSubtract(
												floatSubtract(
													100.0,
													row[avaConstant].Target || benchmark
												),
												floatSubtract(100.0, reliab)
											)
										);
										(row[avaConstant].Next9DailyCumulativeBudget as number[]).push(
											floatSubtract(
												floatSubtract(
													100.0,
													row[avaConstant].Next9Target || benchmark
												),
												floatSubtract(100.0, reliab)
											)
										);
									}
									if (!row[avaConstant].FullData[dateKey])
										row[avaConstant].FullData[dateKey] = [];
									row[avaConstant].FullData[dateKey].push(dataLob);

									// add to Minor overallc
									(row['Overall'].Reliability as number[]).push(reliab);

									//New EB Calculation
									if (reliab !== undefined && reliab !== null) {
										(row['Overall'].DailyCumulativeBudget as number[]).push(
											floatSubtract(
												floatSubtract(
													100.0,
													row['Overall'].Target || benchmark
												),
												floatSubtract(100.0, reliab)
											)
										);
										(row['Overall'].Next9DailyCumulativeBudget as number[]).push(
											floatSubtract(
												floatSubtract(
													100.0,
													row['Overall'].Next9Target || benchmark
												),
												floatSubtract(100.0, reliab)
											)
										);
									}
									if (!row['Overall'].FullData[dateKey])
										row['Overall'].FullData[dateKey] = [];
									row['Overall'].FullData[dateKey].push(dataLob);

									(tempAggr.Overall.Reliability as number[]).push(reliab);

									//New EB Calculation
									if (reliab !== undefined && reliab !== null) {
										(tempAggr.Overall.DailyCumulativeBudget as number[]).push(
											floatSubtract(
												floatSubtract(
													100.0,
													tempAggr.Overall.Target || benchmark
												),
												floatSubtract(100.0, reliab)
											)
										);
										(tempAggr.Overall.Next9DailyCumulativeBudget as number[]).push(
											floatSubtract(
												floatSubtract(
													100.0,
													tempAggr.Overall.Next9Target || benchmark
												),
												floatSubtract(100.0, reliab)
											)
										);
									}
									(tempAggr[avaConstant].Reliability as number[]).push(reliab);

									//New EB Calculation
									if (reliab !== undefined && reliab !== null) {
										(
											tempAggr[avaConstant].DailyCumulativeBudget as number[]
										).push(
											floatSubtract(
												floatSubtract(
													100.0,
													tempAggr[avaConstant].Target || benchmark
												),
												floatSubtract(100.0, reliab)
											)
										);
										(
											tempAggr[avaConstant].Next9DailyCumulativeBudget as number[]
										).push(
											floatSubtract(
												floatSubtract(
													100.0,
													tempAggr[avaConstant].Next9Target || benchmark
												),
												floatSubtract(100.0, reliab)
											)
										);
									}
									if (!tempAggr[avaConstant].FullData[dateKey])
										tempAggr[avaConstant].FullData[dateKey] = [];
									tempAggr[avaConstant].FullData[dateKey].push(dataLob);

									(tempAggr['Overall'].Reliability as number[]).push(reliab);

									//New EB Calculation
									(tempAggr['Overall'].DailyCumulativeBudget as number[]).push(
										floatSubtract(
											floatSubtract(
												100.0,
												tempAggr['Overall'].Target || benchmark
											),
											floatSubtract(100.0, reliab)
										)
									);
									(tempAggr['Overall'].Next9DailyCumulativeBudget as number[]).push(
										floatSubtract(
											floatSubtract(
												100.0,
												tempAggr['Overall'].Next9Target || benchmark
											),
											floatSubtract(100.0, reliab)
										)
									);

									if (!tempAggr['Overall'].FullData[dateKey])
										tempAggr['Overall'].FullData[dateKey] = [];
									tempAggr['Overall'].FullData[dateKey].push(dataLob);

									if (sg && scenarioGroups[sg]) {
										(
											scenarioGroups[sg][avaConstant].Reliability as number[]
										).push(reliab);

										//New EB Calculation
										if (reliab) {
											(
												scenarioGroups[sg][avaConstant]
													.DailyCumulativeBudget as number[]
											).push(
												floatSubtract(
													floatSubtract(
														100.0,
														scenarioGroups[sg][avaConstant].Target ||
														benchmark
													),
													floatSubtract(100.0, reliab)
												)
											);
											(
												scenarioGroups[sg][avaConstant]
													.Next9DailyCumulativeBudget as number[]
											).push(
												floatSubtract(
													floatSubtract(
														100.0,
														scenarioGroups[sg][avaConstant].Next9Target ||
														benchmark
													),
													floatSubtract(100.0, reliab)
												)
											);
										}

										if (!scenarioGroups[sg][avaConstant].FullData[dateKey])
											scenarioGroups[sg][avaConstant].FullData[dateKey] = [];
										scenarioGroups[sg][avaConstant].FullData[dateKey].push(
											dataLob
										);

										(
											scenarioGroups[sg]['Overall'].Reliability as number[]
										).push(reliab);

										//New EB Calculation
										if (reliab !== undefined && reliab !== null) {
											(
												scenarioGroups[sg]['Overall']
													.DailyCumulativeBudget as number[]
											).push(
												floatSubtract(
													floatSubtract(
														100.0,
														scenarioGroups[sg]['Overall'].Target ||
														benchmark
													),
													floatSubtract(100.0, reliab)
												)
											);
											(
												scenarioGroups[sg]['Overall']
													.Next9DailyCumulativeBudget as number[]
											).push(
												floatSubtract(
													floatSubtract(
														100.0,
														scenarioGroups[sg]['Overall'].Next9Target ||
														benchmark
													),
													floatSubtract(100.0, reliab)
												)
											);
										}

										if (!scenarioGroups[sg]['Overall'].FullData[dateKey])
											scenarioGroups[sg]['Overall'].FullData[dateKey] = [];
										scenarioGroups[sg]['Overall'].FullData[dateKey].push(
											dataLob
										);
									} else {
										(
											scenarioGroups['Unspecified'][avaConstant]
												.Reliability as number[]
										).push(reliab);

										//New EB Calculation
										if (reliab !== undefined && reliab !== null) {
											(
												scenarioGroups['Unspecified'][avaConstant]
													.DailyCumulativeBudget as number[]
											).push(
												floatSubtract(
													floatSubtract(
														100.0,
														scenarioGroups['Unspecified'][avaConstant]
															.Target || benchmark
													),
													floatSubtract(100.0, reliab)
												)
											);
											(
												scenarioGroups['Unspecified'][avaConstant]
													.Next9DailyCumulativeBudget as number[]
											).push(
												floatSubtract(
													floatSubtract(
														100.0,
														scenarioGroups['Unspecified'][avaConstant]
															.Next9Target || benchmark
													),
													floatSubtract(100.0, reliab)
												)
											);
										}

										if (
											!scenarioGroups['Unspecified'][avaConstant].FullData[
											dateKey
											]
										)
											scenarioGroups['Unspecified'][avaConstant].FullData[
												dateKey
											] = [];
										scenarioGroups['Unspecified'][avaConstant].FullData[
											dateKey
										].push(dataLob);

										(
											scenarioGroups['Unspecified']['Overall']
												.Reliability as number[]
										).push(reliab);

										//New EB Calculation
										if (reliab !== undefined && reliab !== null) {
											(
												scenarioGroups['Unspecified']['Overall']
													.DailyCumulativeBudget as number[]
											).push(
												floatSubtract(
													floatSubtract(
														100.0,
														scenarioGroups['Unspecified']['Overall']
															.Target || benchmark
													),
													floatSubtract(100.0, reliab)
												)
											);
											(
												scenarioGroups['Unspecified']['Overall']
													.Next9DailyCumulativeBudget as number[]
											).push(
												floatSubtract(
													floatSubtract(
														100.0,
														scenarioGroups['Unspecified']['Overall']
															.Next9Target || benchmark
													),
													floatSubtract(100.0, reliab)
												)
											);
										}
										if (
											!scenarioGroups['Unspecified']['Overall'].FullData[
											dateKey
											]
										)
											scenarioGroups['Unspecified']['Overall'].FullData[
												dateKey
											] = [];
										scenarioGroups['Unspecified']['Overall'].FullData[
											dateKey
										].push(dataLob);
									}
								}
							}

							const totalRequests = data[avaConstant].Requests;

							if (totalRequests) {
								// add to Minor channel
								row[avaConstant].Requests =
									(row[avaConstant].Requests || 0) + totalRequests;
								// add to Minor overall
								row['Overall'].Requests =
									(row['Overall'].Requests || 0) + totalRequests;

								if (sg && scenarioGroups[sg]) {
									scenarioGroups[sg][avaConstant].Requests =
										(scenarioGroups[sg][avaConstant].Requests || 0) +
										totalRequests;
									scenarioGroups[sg]['Overall'].Requests =
										(scenarioGroups[sg]['Overall'].Requests || 0) +
										totalRequests;
								} else {
									scenarioGroups['Unspecified'][avaConstant].Requests =
										(scenarioGroups['Unspecified'][avaConstant].Requests || 0) +
										totalRequests;
									scenarioGroups['Unspecified']['Overall'].Requests =
										(scenarioGroups['Unspecified']['Overall'].Requests || 0) +
										totalRequests;
								}
							}

							const successRequests =
								(data[avaConstant].Success != undefined && data[avaConstant].Success != null) ? data[avaConstant].Success :
									(totalRequests && reliab && totalRequests * (reliab / 100));

							if (successRequests != undefined && successRequests != null) {
								// add to Minor channel
								row[avaConstant].Success =
									(row[avaConstant].Success || 0) + successRequests;
								// add to Minor overall
								row['Overall'].Success =
									(row['Overall'].Success || 0) + successRequests;
								if (sg && scenarioGroups[sg]) {
									scenarioGroups[sg][avaConstant].Success =
										(scenarioGroups[sg][avaConstant].Success || 0) +
										successRequests;
									scenarioGroups[sg]['Overall'].Success =
										(scenarioGroups[sg]['Overall'].Success || 0) +
										successRequests;
								} else {
									scenarioGroups['Unspecified'][avaConstant].Success =
										(scenarioGroups['Unspecified'][avaConstant].Success || 0) +
										successRequests;
									scenarioGroups['Unspecified']['Overall'].Success =
										(scenarioGroups['Unspecified']['Overall'].Success || 0) +
										successRequests;
								}
							}

							// Add icm annotations to everything.
							if (
								data[avaConstant].LinkedIcmItems &&
								// data[avaConstant].LinkedIcmItems &&
								(data[avaConstant].LinkedIcmItems as ModifiedIcmItem[]).length
							) {
								data[avaConstant].LinkedIcmItems.forEach((icm: ModifiedIcmItem) => {
									(row[avaConstant].LinkedIcmItems as Set<ModifiedIcmItem>).add(
										icm
									);
									(row['Overall'].LinkedIcmItems as Set<ModifiedIcmItem>).add(
										icm
									);

									(
										tempAggr[avaConstant].LinkedIcmItems as Set<ModifiedIcmItem>
									).add(icm);
									(
										tempAggr['Overall'].LinkedIcmItems as Set<ModifiedIcmItem>
									).add(icm);

									if (sg && scenarioGroups[sg]) {
										(
											scenarioGroups[sg][avaConstant]
												.LinkedIcmItems as Set<ModifiedIcmItem>
										).add(icm);
										(
											scenarioGroups[sg]['Overall']
												.LinkedIcmItems as Set<ModifiedIcmItem>
										).add(icm);
									} else {
										(
											scenarioGroups['Unspecified'][avaConstant]
												.LinkedIcmItems as Set<ModifiedIcmItem>
										).add(icm);
										(
											scenarioGroups['Unspecified']['Overall']
												.LinkedIcmItems as Set<ModifiedIcmItem>
										).add(icm);
									}
								});
							}

							if (
								data[avaConstant].SuggestedIcmItems &&
								(data[avaConstant].SuggestedIcmItems as ModifiedIcmItem[]).length
							) {
								data[avaConstant].SuggestedIcmItems.forEach(
									(icm: ModifiedIcmItem) => {
										(
											row[avaConstant]
												.SuggestedIcmItems as Set<ModifiedIcmItem>
										).add(icm);
										(
											row['Overall'].SuggestedIcmItems as Set<ModifiedIcmItem>
										).add(icm);

										(
											tempAggr[avaConstant]
												.SuggestedIcmItems as Set<ModifiedIcmItem>
										).add(icm);
										(
											tempAggr['Overall']
												.SuggestedIcmItems as Set<ModifiedIcmItem>
										).add(icm);

										if (sg && scenarioGroups[sg]) {
											(
												scenarioGroups[sg][avaConstant]
													.SuggestedIcmItems as Set<ModifiedIcmItem>
											).add(icm);
											(
												scenarioGroups[sg]['Overall']
													.SuggestedIcmItems as Set<ModifiedIcmItem>
											).add(icm);
										} else {
											(
												scenarioGroups['Unspecified'][avaConstant]
													.SuggestedIcmItems as Set<ModifiedIcmItem>
											).add(icm);
											(
												scenarioGroups['Unspecified']['Overall']
													.SuggestedIcmItems as Set<ModifiedIcmItem>
											).add(icm);
										}
									}
								);
							}

							//Add Latency to everything
							const latency = data[avaConstant].Latency;
							if (latency && typeof latency === 'number') {
								(row[avaConstant].Latency as number[]).push(latency);
								(row['Overall'].Latency as number[]).push(latency);

								(tempAggr[avaConstant].Latency as number[]).push(latency);
								(tempAggr['Overall'].Latency as number[]).push(latency);

								if (sg && scenarioGroups[sg]) {
									(scenarioGroups[sg][avaConstant].Latency as number[]).push(
										latency
									);
									(scenarioGroups[sg]['Overall'].Latency as number[]).push(
										latency
									);
								} else {
									(
										scenarioGroups['Unspecified'][avaConstant]
											.Latency as number[]
									).push(latency);
									(
										scenarioGroups['Unspecified']['Overall'].Latency as number[]
									).push(latency);
								}
								(tempAggr.Overall.Latency as number[]).push(latency);
							}
							if (data.Id == 587) {
								const xxx = data.All.MonthlyLatency;
							}
							const monthlylatency = data[avaConstant].MonthlyLatency as MonthlyLatencyModel;
							if (isEntireSingleMonth && data[avaConstant].Requests != null) {
								row[avaConstant].MonthlyLatency = monthlylatency;
								row['Overall'].MonthlyLatency = monthlylatency;
								tempAggr[avaConstant].MonthlyLatency = monthlylatency;
								tempAggr['Overall'].MonthlyLatency = monthlylatency;
								if (sg && scenarioGroups[sg]) {
									scenarioGroups[sg][avaConstant].MonthlyLatency = monthlylatency;
									scenarioGroups[sg]['Overall'].MonthlyLatency = monthlylatency;
								} else {
									scenarioGroups['Unspecified'][avaConstant].MonthlyLatency = monthlylatency;
									scenarioGroups['Unspecified']['Overall'].MonthlyLatency = monthlylatency;
								}
							}

							// Scenario & Customer Keys
							if (!row.CustomerKeys) row.CustomerKeys = data.CustomerKeys;
							if (!row.ScenarioKeys) row.ScenarioKeys = data.ScenarioKeys;

							if (
								data[avaConstant].DataSource &&
								(data[avaConstant].DataSource as string[]).length
							) {
								(data[avaConstant].DataSource as string[]).forEach((ds) =>
									(row[avaConstant].DataSource as Set<string>).add(ds)
								);
							}
						});
					}
				}
			}
		});
	});



	//For each channel, create Summary aggregates for it
	LobsIterator.forEach((avaConstant: LobEnum) => {
		const lobGraduates = graduatedScenarios.filter(
			(i) => i.CustomerId === LobToCustomerKeysMap[avaConstant]
		);
		for (const agp of aggrKeyProps) {
			const isEntireSingleMonth = CheckIfEntireSingleMonth(agp.startDate, agp.endDate);
			// Create minor scenario level aggregates (date rolled up to month/range)
			const currentAgg = fullAggregates[agp.aggrKey];
			const scenarioGroups = currentAgg.scenarioGroups;
			const tempAggr = currentAgg.overall;
			let cumulativeBudget: number | null = null;
			let next9CumulativeBudget: number | null = null;
			let errorProjection: number | null = null;
			let next9ErrorProjection: number | null = null;

			//Each minor and Major Scenario
			// eslint-disable-next-line complexity
			Object.values(currentAgg.minor).forEach((tableRow) => {
				let scenarioLobGraduate = null;
				if (avaConstant !== LobEnum.Overall) {
					scenarioLobGraduate =
						lobGraduates.find((i) => i.ScenarioId === tableRow.Id) ?? null;
				}
				tableRow[avaConstant].GraduatedScenario = scenarioLobGraduate;
				const sg = tableRow.Parent2Id || tableRow.Parent2Name;

				if (!tableRow.Services) tableRow.Services = [];
				else tableRow.Services = [...(tableRow.Services as Set<string>)];

				if (
					avaConstant !== 'Overall' &&
					tableRow.AssociatedChannels &&
					tableRow.AssociatedChannels.length &&
					tableRow.AssociatedChannels.includes(LobEnum[avaConstant])
				) {
					tableRow[avaConstant].Associated = true;
				} else if (
					avaConstant !== 'Overall' &&
					tableRow.AssociatedChannels &&
					tableRow.AssociatedChannels.length
				) {
					tableRow[avaConstant].Associated = false;
				} else {
					tableRow[avaConstant].Associated = null;
				}

				if (
					avaConstant !== 'Overall' &&
					((tableRow[avaConstant].Associated && !tableRow[avaConstant].HasData) ||
						(tableRow[avaConstant].Associated === false &&
							tableRow[avaConstant].HasData))
				) {
					tableRow.IsCovered = false;
				}
				if (!tempAggr[avaConstant].Requests) tempAggr[avaConstant].Requests = 0;
				if (!tempAggr[avaConstant].Reliability) tempAggr[avaConstant].Reliability = [];

				//New EB Calculation
				if (!tempAggr[avaConstant].DailyCumulativeBudget)
					tempAggr[avaConstant].DailyCumulativeBudget = [];
				if (!tempAggr[avaConstant].Next9DailyCumulativeBudget)
					tempAggr[avaConstant].Next9DailyCumulativeBudget = [];
				tempAggr[avaConstant].Requests =
					(tempAggr[avaConstant].Requests || 0) + (tableRow[avaConstant].Requests || 0);
				tableRow[avaConstant].RawValues = (tableRow[avaConstant].Reliability as number[]) //TODO: Temporary
					.filter((val) => val !== null && val !== undefined);
				const avgScenarioChannelReliablity = avgValue(
					(tableRow[avaConstant].Reliability as number[]).filter(
						(val) => val !== null && val !== undefined
					)
				);
				tableRow[avaConstant].Reliability = (avgScenarioChannelReliablity != undefined && avgScenarioChannelReliablity != null) ? Number(avgScenarioChannelReliablity.toPrecision(5)) : null;
				if (isEntireSingleMonth) {
					tableRow[avaConstant].Latency = (tableRow[avaConstant].MonthlyLatency == null || tableRow[avaConstant].MonthlyLatency?.Value == null) ?
						avgValue(
							(tableRow[avaConstant].Latency as number[]).filter(
								(val) => val !== null && val !== undefined
							)
						) : tableRow[avaConstant]?.MonthlyLatency?.Value as number;
				} else {
					tableRow[avaConstant].Latency = null;
				}
				tableRow[avaConstant].Nines = count9(avgScenarioChannelReliablity ? Number(avgScenarioChannelReliablity.toPrecision(5)) : null);

				//New EB Calculation
				let budgets = (tableRow[avaConstant].DailyCumulativeBudget as number[]).filter(
					(val) => val !== null && val !== undefined
				);
				let next9budgets = (tableRow[avaConstant].Next9DailyCumulativeBudget as number[]).filter(
					(val) => val !== null && val !== undefined
				);
				cumulativeBudget = sumValue(budgets);
				if (agp.totalDays && budgets && cumulativeBudget) {
					if (agp.totalDays - budgets.length > 0) {
						errorProjection = floatAdd(
							cumulativeBudget / (agp.totalDays - budgets.length),
							floatSubtract(100, tableRow[avaConstant].Target || benchmark)
						);
					} else {
						tableRow[avaConstant].DailyCumulativeBudget = null;
						errorProjection = 0;
					}
					tableRow[avaConstant].ErrorBudgetRemaining = errorProjection;
				}
				next9CumulativeBudget = sumValue(next9budgets);
				if (agp.totalDays && next9budgets && next9CumulativeBudget) {
					if (agp.totalDays - next9budgets.length > 0 && tableRow.IsNext9) {
						next9ErrorProjection = floatAdd(
							next9CumulativeBudget / (agp.totalDays - budgets.length),
							floatSubtract(100, tableRow[avaConstant].Next9Target || benchmark)
						);
					} else {
						tableRow[avaConstant].Next9DailyCumulativeBudget = null;
						next9ErrorProjection = 0;
					}
					tableRow[avaConstant].Next9ErrorBudgetRemaining = next9ErrorProjection;
				}

				Object.values(scenarioGroups).forEach((sg) => {
					if (Array.isArray(sg[avaConstant].Reliability)) {
						// average Major Scenario channel's reliability if it hasn't been reduced yet
						sg[avaConstant].RawValues = (
							sg[avaConstant].Reliability as number[]
						).filter((val) => val !== null && val !== undefined);
						sg[avaConstant].Reliability = avgValue(
							(sg[avaConstant].Reliability as number[]).filter(
								(val) => val !== null && val !== undefined
							)
						);
						if (isEntireSingleMonth) {
							sg[avaConstant].Latency = (sg[avaConstant].MonthlyLatency == null || sg[avaConstant].MonthlyLatency?.Value == null) ?
								avgValue(
									(sg[avaConstant].Latency as number[]).filter(
										(val) => val !== null && val !== undefined
									)
								) : sg[avaConstant]?.MonthlyLatency?.Value as number;
						} else {
							sg[avaConstant].Latency = null;
						}
						sg[avaConstant].Nines = count9(sg[avaConstant].Reliability as number);
						budgets = (sg[avaConstant].DailyCumulativeBudget as number[]).filter(
							(val) => val !== null && val !== undefined
						);
						next9budgets = (sg[avaConstant].Next9DailyCumulativeBudget as number[]).filter(
							(val) => val !== null && val !== undefined
						);
						cumulativeBudget = sumValue(budgets);
						next9CumulativeBudget = sumValue(next9budgets);
					}

					//New EB Calculation
					if (agp.totalDays && budgets && cumulativeBudget) {
						if (agp.totalDays - budgets.length > 0) {
							errorProjection = floatAdd(
								cumulativeBudget / (agp.totalDays - budgets.length),
								floatSubtract(100, sg[avaConstant].Target || benchmark)
							);
						} else {
							sg[avaConstant].DailyCumulativeBudget = null;
							errorProjection = 0;
						}
						sg[avaConstant].ErrorBudgetRemaining = errorProjection;
					}
					if (agp.totalDays && next9budgets && next9CumulativeBudget) {
						if (agp.totalDays - next9budgets.length > 0) {
							next9ErrorProjection = floatAdd(
								next9CumulativeBudget / (agp.totalDays - budgets.length),
								floatSubtract(100, sg[avaConstant].Target || benchmark)
							);
						} else {
							sg[avaConstant].Next9DailyCumulativeBudget = null;
							next9ErrorProjection = 0;
						}
						sg[avaConstant].Next9ErrorBudgetRemaining = next9ErrorProjection;
					}
				});

				if (avgScenarioChannelReliablity || avgScenarioChannelReliablity === 0) {
					const target = tableRow[avaConstant].Target || benchmark;
					if (Math.floor(avgScenarioChannelReliablity * 1000) / 1000 >= target) {
						//date, minor, channel level
						//date, channel level

						if (avaConstant !== 'Overall') {
							tableRow['Overall'].MinorsInSlo =
								(tableRow['Overall'].MinorsInSlo || 0) + 1;

							tempAggr[avaConstant].MinorsInSlo =
								(tempAggr[avaConstant].MinorsInSlo || 0) + 1;
							tempAggr['Overall'].MinorsInSlo =
								(tempAggr['Overall'].MinorsInSlo || 0) + 1;

							if (sg && scenarioGroups[sg]) {
								scenarioGroups[sg][avaConstant].MinorsInSlo =
									(scenarioGroups[sg][avaConstant].MinorsInSlo || 0) + 1;
								scenarioGroups[sg]['Overall'].MinorsInSlo =
									(scenarioGroups[sg]['Overall'].MinorsInSlo || 0) + 1;
							} else {
								scenarioGroups['Unspecified'][avaConstant].MinorsInSlo =
									(scenarioGroups['Unspecified'][avaConstant].MinorsInSlo || 0) +
									1;
								scenarioGroups['Unspecified']['Overall'].MinorsInSlo =
									(scenarioGroups['Unspecified']['Overall'].MinorsInSlo || 0) + 1;
							}
						}
					} else if (avaConstant !== 'Overall') {
						tableRow['Overall'].MinorsNotInSlo =
							(tableRow['Overall'].MinorsNotInSlo || 0) + 1;

						const aboveTarget = avgScenarioChannelReliablity >= benchmark;
						if ((showGreen && aboveTarget) || (showRed && !aboveTarget)) {
							tempAggr[avaConstant].MinorsNotInSlo =
								(tempAggr[avaConstant].MinorsNotInSlo || 0) + 1;
							(tempAggr[avaConstant].Reliability as number[]).push(
								avgScenarioChannelReliablity
							);

							tempAggr['Overall'].MinorsNotInSlo =
								(tempAggr['Overall'].MinorsNotInSlo || 0) + 1;
							(tempAggr['Overall'].Reliability as number[]).push(
								avgScenarioChannelReliablity
							);
						}

						if (sg && scenarioGroups[sg]) {
							scenarioGroups[sg][avaConstant].MinorsNotInSlo =
								(scenarioGroups[sg][avaConstant].MinorsNotInSlo || 0) + 1;
							scenarioGroups[sg]['Overall'].MinorsNotInSlo =
								(scenarioGroups[sg]['Overall'].MinorsNotInSlo || 0) + 1;
						} else {
							scenarioGroups['Unspecified'][avaConstant].MinorsNotInSlo =
								(scenarioGroups['Unspecified'][avaConstant].MinorsNotInSlo || 0) +
								1;
							scenarioGroups['Unspecified']['Overall'].MinorsNotInSlo =
								(scenarioGroups['Unspecified']['Overall'].MinorsNotInSlo || 0) + 1;
						}
					}
				}

				if (avaConstant !== 'Overall') {
					//Icm Annotations
					if (tableRow[avaConstant].LinkedIcmItems instanceof Set)
						tableRow[avaConstant].LinkedIcmItems = [
							...tableRow[avaConstant].LinkedIcmItems,
						];
					if (tableRow['Overall'].LinkedIcmItems instanceof Set)
						tableRow['Overall'].LinkedIcmItems = [
							...tableRow['Overall'].LinkedIcmItems,
						];

					//Icm Annotations
					if (tableRow[avaConstant].SuggestedIcmItems instanceof Set)
						tableRow[avaConstant].SuggestedIcmItems = [
							...tableRow[avaConstant].SuggestedIcmItems,
						];
					if (tableRow['Overall'].SuggestedIcmItems instanceof Set)
						tableRow['Overall'].SuggestedIcmItems = [
							...tableRow['Overall'].SuggestedIcmItems,
						];

					if (tableRow[avaConstant].DataSource instanceof Set)
						tableRow[avaConstant].DataSource = [
							...(tableRow[avaConstant].DataSource as Set<string>),
						];
				}
				// }
			});

			//IcmItems for overall aggregates
			if (avaConstant !== 'Overall') {
				if (tempAggr[avaConstant].LinkedIcmItems instanceof Set)
					tempAggr[avaConstant].LinkedIcmItems = [
						...tempAggr[avaConstant].LinkedIcmItems,
					];
				if (tempAggr['Overall'].LinkedIcmItems instanceof Set)
					tempAggr['Overall'].LinkedIcmItems = [...tempAggr['Overall'].LinkedIcmItems];

				if (tempAggr[avaConstant].SuggestedIcmItems instanceof Set)
					tempAggr[avaConstant].SuggestedIcmItems = [
						...tempAggr[avaConstant].SuggestedIcmItems,
					];
				if (tempAggr['Overall'].SuggestedIcmItems instanceof Set)
					tempAggr['Overall'].SuggestedIcmItems = [
						...tempAggr['Overall'].SuggestedIcmItems,
					];

				Object.values(scenarioGroups).forEach((sg) => {

					if (sg[avaConstant].LinkedIcmItems instanceof Set)
						sg[avaConstant].LinkedIcmItems = [...sg[avaConstant].LinkedIcmItems];
					if (sg['Overall'].LinkedIcmItems instanceof Set)
						sg['Overall'].LinkedIcmItems = [...sg['Overall'].LinkedIcmItems];

					if (sg[avaConstant].SuggestedIcmItems instanceof Set)
						sg[avaConstant].SuggestedIcmItems = [...sg[avaConstant].SuggestedIcmItems];
					if (sg['Overall'].SuggestedIcmItems instanceof Set)
						sg['Overall'].SuggestedIcmItems = [...sg['Overall'].SuggestedIcmItems];
				});
			}
		}
	});

	for (const aggrKeyProp of aggrKeyProps) {
		const isEntireSingleMonth = CheckIfEntireSingleMonth(aggrKeyProp.startDate, aggrKeyProp.endDate);
		const currentAgg = fullAggregates[aggrKeyProp.aggrKey];
		const tempAggr = currentAgg.overall;
		let filteredRawValues: number[];
		let errorProjection;
		let next9ErrorProjection;
		let cumulativeBudget;
		let next9CumulativeBudget;
		LobsIterator.forEach((Lob) => {
			filteredRawValues = (tempAggr[Lob].Reliability as number[]).filter(
				(val) => val !== null && val !== undefined
			);
			tempAggr[Lob].RawValues = filteredRawValues;
			tempAggr[Lob].Reliability = avgValue(filteredRawValues);
			tempAggr[Lob].Nines = count9(tempAggr[Lob].Reliability as number);
			if (isEntireSingleMonth) {
				tempAggr[Lob].Latency = (tempAggr[Lob].MonthlyLatency == null || tempAggr[Lob].MonthlyLatency?.Value == null) ?
					avgValue(
						(tempAggr[Lob].Latency as number[]).filter(
							(val) => val !== null && val !== undefined
						)
					) : tempAggr[Lob]?.MonthlyLatency?.Value as number;
			} else {
				tempAggr[Lob].Latency = null;
			}

			//New EB Calculation
			const budgets = (tempAggr[Lob].DailyCumulativeBudget as number[]).filter(
				(val) => val !== null && val !== undefined
			);
			cumulativeBudget = sumValue(budgets);
			const next9budgets = (tempAggr[Lob].Next9DailyCumulativeBudget as number[]).filter(
				(val) => val !== null && val !== undefined
			);
			next9CumulativeBudget = sumValue(budgets);
			//New EB Calculation
			if (aggrKeyProp.totalDays && budgets && cumulativeBudget) {
				if (aggrKeyProp.totalDays - budgets.length - 1 > 0) {
					errorProjection = floatAdd(
						cumulativeBudget / (aggrKeyProp.totalDays - budgets.length),
						floatSubtract(100, tempAggr[Lob].Target || benchmark)
					);
				} else {
					tempAggr[Lob].DailyCumulativeBudget = null;
					errorProjection = 0;
				}
				tempAggr[Lob].ErrorBudgetRemaining = errorProjection;
			}
			if (aggrKeyProp.totalDays && next9budgets && next9CumulativeBudget) {
				if (aggrKeyProp.totalDays - next9budgets.length - 1 > 0) {
					next9ErrorProjection = floatAdd(
						next9CumulativeBudget / (aggrKeyProp.totalDays - budgets.length),
						floatSubtract(100, tempAggr[Lob].Target || benchmark)
					);
				} else {
					tempAggr[Lob].Next9DailyCumulativeBudget = null;
					next9ErrorProjection = 0;
				}
				tempAggr[Lob].Next9ErrorBudgetRemaining = next9ErrorProjection;
			}
		});
		currentAgg.minor = Object.values(currentAgg.minor);
	}

	// const endPerf = performance.now()

	callback(fullAggregates as IFinalFullAggregateCache);
}

//TODO: type this appropraiately
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function filterData(
	fullData: ModifiedApiDataMap,
	startDate: string,
	endDate: string,
	globalFilters: Partial<AggregateFilters> | IGlobalFiltersState,
	currentPage: string,
	routeId: string,
) {
	const aggregateFilters: AggregateFilters = {
		benchmark: 99.99,
		customTargets: true,
		inSlo: 'all',
		executiveFilter: 'Executive',
		scenarioGroup: '',
		source: 'all',
		groupBy: 'scenario',
		hiddenScenarioGroups: [],
		ownerManagers: globalFilters.ownerManagers || {},
		...globalFilters,
		engineeringManager: globalFilters.engineeringManager || '',
		owner: globalFilters.owner || '',
		keyword: globalFilters.keyword || '',
	};

	const filtered: ModifiedApiDataMap = {};
	for (const pair of Object.entries(fullData)) {
		const dateKey = pair[0];
		const dateData = pair[1];

		if (dateKey >= startDate && dateKey < endDate) {
			filtered[dateKey] = filterScenarios(
				dateData,
				aggregateFilters,
				currentPage,
				routeId
			) as ModifiedCoreApiData[];
		}
	}
	return filtered;
}

function filterScenarios(
	dateData: MetaData[] | ModifiedCoreApiData[],
	globalFilters: AggregateFilters,
	currentPage: string,
	routeId?: string
): (MetaData | ModifiedCoreApiData)[] {
	const missingParents = new Set();
	const parents = new Set();
	const allParents = [];
	const filteredParents = [];
	const filteredChildren = [];


	for (const scenario of dateData) {
		if (scenario.Parent1Id === 0) allParents.push(scenario);
		//TODO: avoid extra checks if the previous has failed
		const matchesExecutive = checkExecutive(scenario, globalFilters);
		const matchesKeyword = checkKeywords(scenario, globalFilters, currentPage);
		const matchesEngineeringManager = checkEngineeringManager(scenario, globalFilters);
		const matchesOwner = checkOwner(scenario, globalFilters);
		const matchesScenarioGroup = checkScenarioGroup(
			scenario,
			globalFilters,
			currentPage,
			routeId
		);
		const matchesCustomTargets = checkCustomTargets(
			scenario as ModifiedCoreApiData,
			globalFilters
		);
		if (
			matchesExecutive &&
			matchesKeyword &&
			matchesEngineeringManager &&
			matchesOwner &&
			matchesScenarioGroup &&
			matchesCustomTargets
		) {
			//valid parent
			if (scenario.Parent1Id === 0) {
				parents.add(scenario.Id);
				if (missingParents.has(scenario.Id)) missingParents.delete(scenario.Id);
				filteredParents.push(scenario);
			}

			//valid child
			if (scenario.Parent1Id !== 0) {
				if (!parents.has(scenario.Parent1Id)) {
					missingParents.add(scenario.Parent1Id);
				}
				filteredChildren.push(scenario);
			}
		}
	}
	const strayParents = [];
	for (const parent of allParents) {
		if (missingParents.has(parent.Id)) strayParents.push(parent);
	}
	const x = filteredParents.concat(strayParents, filteredChildren);
	return x;
}

function checkExecutive(
	scenario: MetaData | ModifiedCoreApiData,
	globalFilters: AggregateFilters
): boolean {
	if (globalFilters.executiveFilter == 'All') return true;
	return (scenario.IsExecutive && globalFilters.executiveFilter == 'Executive') || (!scenario.IsExecutive && globalFilters.executiveFilter == 'NonExecutive');
}

function CheckIfEntireSingleMonth(
	startDate: string,
	endDate: string,
): boolean {
	const startyear = parseInt(startDate.slice(0, 4), 10);
	const startmonth = parseInt(startDate.slice(4, 6), 10) - 1; // Month is zero-based
	const startday = parseInt(startDate.slice(6, 8), 10);
	const endyear = parseInt(endDate.slice(0, 4), 10);
	const endmonth = parseInt(endDate.slice(4, 6), 10) - 1; // Month is zero-based
	const endday = parseInt(endDate.slice(6, 8), 10);

	const start = new Date(startyear, startmonth, startday);
	const end = new Date(endyear, endmonth, endday);
	// Check if both are the first day of their months
	const isStartDateFirstOfMonth = start.getDate() === 1;
	const isEndDateFirstOfMonth = end.getDate() === 1;

	// Check if startDate is exactly one month before endDate
	const nextMonthStartDate = new Date(start);
	nextMonthStartDate.setMonth(start.getMonth() + 1);

	const isOneMonthBefore =
		nextMonthStartDate.getFullYear() === end.getFullYear() &&
		nextMonthStartDate.getMonth() === end.getMonth();

	return isStartDateFirstOfMonth && isEndDateFirstOfMonth && isOneMonthBefore;
}
function checkKeywords(
	scenario: MetaData | ModifiedCoreApiData,
	globalFilters: AggregateFilters,
	currentPage = 'scenariogroup'
): boolean {
	const { groupBy } = globalFilters;
	if (!globalFilters.keyword) return true;

	const lowerKeyword = globalFilters.keyword.toLowerCase();
	switch (true) {
		case groupBy === 'scenarioGroup' || currentPage === 'scenariogroup':
			return (
				scenario.Name.toLowerCase().indexOf(lowerKeyword) > -1 ||
				(scenario.ScenarioKey == undefined ? (scenario.ScenarioKeys?.find((key) => key.toLowerCase().indexOf(lowerKeyword) > -1) != undefined)
					: (scenario.ScenarioKey?.toLowerCase().indexOf(lowerKeyword) > -1)) ||
				scenario.Parent2Name.toLowerCase().indexOf(lowerKeyword) > -1
			);
		case groupBy === 'scenario':
			return (
				scenario.Name.toLowerCase().indexOf(lowerKeyword) > -1 ||
				(scenario.ScenarioKey == undefined ? (scenario.ScenarioKeys?.find((key) => key.toLowerCase().indexOf(lowerKeyword) > -1) != undefined)
					: (scenario.ScenarioKey?.toLowerCase().indexOf(lowerKeyword) > -1)) ||
				scenario.Parent1Name.toLowerCase().indexOf(lowerKeyword) > -1 ||
				scenario.Parent2Name.toLowerCase().indexOf(lowerKeyword) > -1
			);
		case groupBy === 'service':
			return (
				scenario.Name.toLowerCase().indexOf(lowerKeyword) > -1 ||
				(scenario.ScenarioKey == undefined ? (scenario.ScenarioKeys?.find((key) => key.toLowerCase().indexOf(lowerKeyword) > -1) != undefined)
					: (scenario.ScenarioKey?.toLowerCase().indexOf(lowerKeyword) > -1)) ||
				scenario.Parent1Name.toLowerCase().indexOf(lowerKeyword) > -1
			);
		default:
			return false;
	}
}

function checkEngineeringManager(
	scenario: MetaData | ModifiedCoreApiData,
	globalFilters: AggregateFilters
): boolean {
	if (!globalFilters.engineeringManager) return true;
	if (!scenario.EngineeringManagerAlias) {
		if (scenario.OwnerAlias?.toLowerCase() === globalFilters.engineeringManager.toLowerCase()) {
			return true;
		}
		return false;
	}
	return (
		scenario.EngineeringManagerAlias.toLowerCase().indexOf(
			globalFilters.engineeringManager.toLowerCase()
		) > -1
	);
}

function findKeysMatchingValue(globalFilters: AggregateFilters) {
	const matchingKeys: string[] = [];
	const searchOwner: string = (globalFilters.owner ?? '').toLowerCase();
	matchingKeys.push(searchOwner);
	for (const key in globalFilters?.ownerManagers) {
		if (globalFilters?.ownerManagers[key].Managers?.includes(searchOwner.toUpperCase())) {
			matchingKeys.push(key.toLowerCase());
		}
	}

	return matchingKeys;
}

function checkOwner(
	scenario: MetaData | ModifiedCoreApiData,
	globalFilters: AggregateFilters
): boolean {
	if (!globalFilters.owner) return true;
	if (!(scenario as ModifiedCoreApiData).OwnerAlias) return false;
	const ownerChain: string[] = findKeysMatchingValue(globalFilters);
	return (
		ownerChain.includes(((scenario as ModifiedCoreApiData).OwnerAlias ?? '')
			.toLowerCase())

	)
}

function checkScenarioGroup(
	scenario: MetaData | ModifiedCoreApiData,
	globalFilters: AggregateFilters,
	currentPage: string,
	routeId?: string
): boolean {
	const matchingPage = currentPage && currentPage === 'scenariogroup';
	const notAll = routeId !== 'all';

	const tempParent = scenario.Parent2Name && scenario.Parent2Name.toLowerCase();
	if (
		matchingPage &&
		notAll &&
		routeId &&
		tempParent !== routeId &&
		scenario.Name.toLowerCase() !== routeId
	)
		return false;
	if (!globalFilters.hiddenScenarioGroups || scenario.Parent1Id === 0) return true;
	return !!tempParent && globalFilters.hiddenScenarioGroups.indexOf(tempParent) === -1;
}

function checkCustomTargets(
	scenario: ModifiedCoreApiData,
	globalFilters: AggregateFilters
): boolean {
	if (globalFilters.customTargets || scenario.Parent1Id === 0) return true;
	return LobsIterator.slice(0, 9).reduce((acc: boolean, cur: LobEnum) => {
		if (!acc) return false;
		if (!scenario[cur] || !scenario[cur].Target) return true;
		return scenario[cur].Target === null;
	}, true);
}

function count9(reliability: number | null): Nines | undefined {
	if (!(reliability || reliability === 0)) return undefined;
	if (reliability === 100) return 4;

	const temp = reliability < 90 ? ['9'] : (reliability * 100).toString().split('');
	let count = 0;
	let foundNot9 = false;

	for (let i = 0; i < 4; i++) {
		if (foundNot9) continue;

		if (temp[i] !== '9') foundNot9 = true;
		else count++;
	}
	return count as Nines;
}
