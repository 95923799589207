import { Dialog, DialogContent, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import ScenarioHealthApi from 'API/ScenarioHealthApi';
import { AvailabilityOptions } from 'API/ScenarioHealthApi.types';
import {
	Action,
	CoreDataActions,
	CoreDataDispatch,
	CoreDataState,
	Status,
} from 'Context/CoreDataContext/CoreDataContext.types';
import { LobsIterator } from 'DataModels/CoreDataModels';
import {
	CoreApiDataMap,
	GraduatedScenario,
	ISelfServeScenarios,
	MetaData,
	MinorScenarioMetaData,
	ModifiedApiDataMap,
	ModifiedCoreApiData,
	ModifiedGraduatedScenario,
	PeopleMetadata,
	ScenarioGroupMetadata,
	ScenarioGroupTypeData,
	UserMetadata
} from 'DataModels/CoreDataModels.types';
import { CustomerId } from 'DataModels/Global.types';
import isEmpty from 'Utils/IsEmpty';
import { TelemetryService } from 'Utils/TelemetryService';
import VF from 'Utils/ValueFormatting';
import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';
/* eslint-disable */
const { v4: uuidv4 } = require('uuid');
const initialState: CoreDataState = {
	coreScenarioData: {},
	scenarioStatus: 'empty',
	metaDataStatus: 'empty',
	managementMetadataStatus: 'empty',
	emMetaData: {},
	ownersMetaData: {},
	scenarioMetaData: [],
	selfServeScenarioMetaData: [],
	majorScenarioList: [],
	activeMinorScenarioList: [],
	fullMinorScenarioList: [],
	scenarioGroupList: [],
	userMetadata: {
		Alias: '',
		EngineeringManagerAlias: '',
		EngineeringManagerName: '',
		Name: '',
		TeamShortName: '',
		ProfileImage: '',
		CanApprovePendingScenarios: false,
	},
	graduatedScenarios: [],
	scenarioGroupTypeData: []
};

const DataProviderContext = createContext<CoreDataState | undefined>(undefined);
const DataProviderDispatch = createContext<CoreDataDispatch | undefined>(undefined);

function checkMetaDataStatus({
	emMetaData,
	ownersMetaData,
	scenarioMetaData,
	selfServeScenarioMetaData,
}: CoreDataState): Status {
	if (
		!isEmpty(emMetaData) &&
		!isEmpty(ownersMetaData) &&
		scenarioMetaData.length !== 0 &&
		selfServeScenarioMetaData.length !== 0
	)
		return 'success';
	return 'fetching';
}

function loadCoreData(
	dispatch: CoreDataDispatch,
	apiParams: Partial<AvailabilityOptions>,
	getFullScenarios: boolean
) {
	const mergedParams: AvailabilityOptions = {
		monthYear: '6months',
		source: 'all',
		serviceCategory: 'Front Door',
		executive: 'All',
		groupBy: 'scenario',
		...apiParams,
	};

	void ScenarioHealthApi.loadCoreAvailabilityData(mergedParams, (res: CoreApiDataMap) => {
		dispatch({
			type: CoreDataActions.FetchedScenarios,
			scenarioData: transformApiData(res, true),
		});
		dispatch({
			type: CoreDataActions.GenerateMetaDataLists,
			dispatch: dispatch,
			getFullScenarios,
		});
	},
		(err: string) => {
			dispatch({
				type: CoreDataActions.ApiError,
				error: err,
			});
		}
	);
}

function loadMetaData(dispatch: CoreDataDispatch, getFullScenarios: boolean) {
	//React Strict Mode will make these api calls twice.  It will only happen in development and will not be present in the builds
	loadScenarioMetadata(dispatch, getFullScenarios);
	loadSelfServeScenariosMetaData(dispatch, getFullScenarios);

	void ScenarioHealthApi.loadEngineeringManagersData(
		(engineeringManagersRes: PeopleMetadata[]) => {
			dispatch({
				type: CoreDataActions.FetchedEMMetaData,
				emMetaData: engineeringManagersRes,
			});
		},
		(err: string) => {
			dispatch({
				type: CoreDataActions.ApiError,
				error: err,
			});
		}
	);
	void ScenarioHealthApi.loadScenarioGroupTypeData(
		(scenarioGroupsTypeRes: ScenarioGroupTypeData[]) => {
			dispatch({
				type: CoreDataActions.FetchedScenarioGroupTypeData,
				scenarioGroupTypeData: scenarioGroupsTypeRes,
			});
		},
		(err: string) => {
			dispatch({
				type: CoreDataActions.ApiError,
				error: err,
			});
		}
	);
	void ScenarioHealthApi.loadOwnersData((ownersRes: PeopleMetadata[]) => {
		dispatch({ type: CoreDataActions.FetchedOwnersMetaData, ownersMetaData: ownersRes });
	},
		(err: string) => {
			dispatch({
				type: CoreDataActions.ApiError,
				error: err,
			});
		});

	void ScenarioHealthApi.getUserMetada((res: UserMetadata) => {
		res.EngineeringManagerAlias = res.EngineeringManagerAlias?.toLowerCase() ?? '';
		dispatch({ type: CoreDataActions.FetchedUserMetaData, userMetadata: res });
	},
		(err: string) => {
			dispatch({
				type: CoreDataActions.ApiError,
				error: err,
			});
		});

	void ScenarioHealthApi.getGraduatedScenarios((res) => {
		dispatch({
			type: CoreDataActions.FetchedGraduatedScenarios,
			graduatedScenarios: transformGraduatedScenarios(res),
		});
	},
		(err: string) => {
			dispatch({
				type: CoreDataActions.ApiError,
				error: err,
			});
		});
}

function loadScenarioMetadata(dispatch: CoreDataDispatch, getFullScenarios = true) {
	void ScenarioHealthApi.loadScenariosListData(
		(scenariosListRes: MetaData[]) => {
			dispatch({
				type: CoreDataActions.FetchedScenariosMetaData,
				scenarioMetaData: scenariosListRes,
			});
			dispatch({
				type: CoreDataActions.GenerateMetaDataLists,
				dispatch: dispatch,
				getFullScenarios,
			});
		},
		(err: string) => {
			dispatch({
				type: CoreDataActions.ApiError,
				error: err,
			});
		},
		!getFullScenarios,
		!getFullScenarios
	);
}

function loadSelfServeScenariosMetaData(dispatch: CoreDataDispatch, getFullScenarios = true) {
	void ScenarioHealthApi.getAllSelfServeScenarios(
		(selfServeScenarios: ISelfServeScenarios[]) => {
			for (var scenario of selfServeScenarios) {
				if (scenario.DataSources) {
					for (var ds of scenario.DataSources) {
						ds.id = uuidv4();
						ds.SelfServeDataSourceStatus = 'None';
					}
				}
			}
			dispatch({
				type: CoreDataActions.FetchedSelfServeScenariosMetaData,
				selfServeScenarioMetaData: selfServeScenarios,
			});
			dispatch({
				type: CoreDataActions.GenerateMetaDataLists,
				dispatch: dispatch,
				getFullScenarios,
			});
		},
		(err: string) => {
			dispatch({
				type: CoreDataActions.ApiError,
				error: err,
			});
		},
		!getFullScenarios,
		!getFullScenarios
	);
}

function transformApiData(apiData: CoreApiDataMap, parseParentIds = false): ModifiedApiDataMap {
	let hasData = false;
	for (const day in apiData) {
		if (apiData[day]) {
			if (!hasData && apiData[day].length) hasData = true;

			for (const scenario of apiData[day]) {
				const modifiedScenario = scenario as ModifiedCoreApiData;
				modifiedScenario.Id = Number(scenario.Id);
				if (parseParentIds) {
					modifiedScenario.Parent1Id = Number(scenario.Parent1Id);
				}
				//Modify unespecified scenario names
				if (modifiedScenario.Id > 1000) {
					modifiedScenario.Parent1Name = scenario.Parent1Name || 'Unspecified';
					modifiedScenario.Parent2Name = scenario.Parent2Name || 'Unspecified';
				}
				LobsIterator.forEach((lob) => {
					if (scenario[lob] && scenario[lob]?.DataSource) {
						modifiedScenario[lob].DataSource = (
							scenario[lob]?.DataSource as string
						).split(',');
					}
				});
			}
		}
	}

	if (!hasData) TelemetryService.trackException('Empty Availability Data', {});

	return apiData as ModifiedApiDataMap;
}

function transformScenariosList(
	scenariosMetaData: MetaData[],
	coreScenarioData: ModifiedApiDataMap
) {
	//early exit to prevent duplicate work.
	if (!scenariosMetaData.length || isEmpty(coreScenarioData)) return null;
	const majorScenarioList: MetaData[] = [];
	let activeMinorScenarioList: MinorScenarioMetaData[] = [];
	const inactiveMinorScenarioList: MinorScenarioMetaData[] = [];
	const scenarioGroupMap: Record<string, number> = {};
	const scenarioGroupScenarioMap: Record<string, number[]> = {};
	const ids = new Set<number>();

	scenariosMetaData.forEach((ele) => {
		const id = Number(ele.ScenarioID);
		ids.add(id);

		if (ele.ParentScenarioID === 0) {
			majorScenarioList.push({
				...ele,
				Id: id,
				IsExecutive: ele.IsExecutive,
				Name: ele.ScenarioName,
				Order: ele.Order,
				Parent1Id: 0,
				Parent1Name: ele.ScenarioName,
				Parent2Id: null,
				Parent2Name: ele.ScenarioGroup || 'Unspecified',
				EngineeringManagerAlias: ele.L4ManagerAlias.toLowerCase(),
				EngineeringManager: ele.L4ManagerName,
			});
		} else {
			const minor = {
				...ele,
				AssociatedChannels: ele.Channels ? ele.Channels.split(',') : [],
				EngineeringManagerAlias: ele.L4ManagerAlias.toLowerCase(),
				EngineeringManager: ele.L4ManagerName,
				Id: id,
				IsExecutive: ele.IsExecutive,
				Name: ele.ScenarioName,
				Order: ele.Order,
				OwnerAlias: ele.Owner || '',
				Owner: ele.OwnerName || '',
				Parent1Id: Number(ele.ParentScenarioID),
				Parent1Name: ele.ParentScenarioName,
				Parent2Id: ele.ScenarioGroup || 'Unspecified',
				Parent2Name: ele.ScenarioGroup || 'Unspecified',
				SegmentOwner: ele.SegmentOwner || '',
				Source: ele.IsSll ? 'sll' : 'nonsll',
				// STID: ele.GUID,
				CreatedAt: ele.CreatedAt ? ele.CreatedAt.slice(0, 10).replace(/-/g, '') : '',
			};

			if (minor.IsActive) {
				activeMinorScenarioList.push(minor);

				const key: string = ele.ScenarioGroup === '' ? 'Unspecified' : ele.ScenarioGroup;
				scenarioGroupMap[key] = !scenarioGroupMap[key] ? 1 : scenarioGroupMap[key] + 1;
				if (!scenarioGroupScenarioMap[key])
					scenarioGroupScenarioMap[key] = [ele.ScenarioID];
				else scenarioGroupScenarioMap[key].push(ele.ScenarioID);
			} else inactiveMinorScenarioList.push(minor);
		}
	});

	//Unspecified Minor Scenario
	const unspecifiedScenarios: MinorScenarioMetaData[] = [];
	for (const date in coreScenarioData) {
		if (coreScenarioData[date].length) {
			for (const scenario of coreScenarioData[date]) {
				//continue if we've already created this one or it's not an unspecified scenario
				const id = Number(scenario.Id);
				if (!scenario.Parent2Name) scenario.Parent2Name = 'Unspecified';

				if (ids.has(id)) continue;
				ids.add(id);

				//Creating Meta data for "Unspecified"
				unspecifiedScenarios.push({
					L4ManagerAlias: scenario.EngineeringManagerAlias
						? scenario.EngineeringManagerAlias.toLowerCase()
						: '',
					L4ManagerName: scenario.EngineeringManager ? scenario.EngineeringManager : '',
					ParentScenarioID: Number(scenario.Parent1Id),
					ParentScenarioName: scenario.Parent1Name,
					ScenarioGroup: 'Unspecified',
					ScenarioName: 'Unspecified',
					AssociatedChannels: scenario.AssociatedChannels
						? scenario.AssociatedChannels
						: [],
					EngineeringManagerAlias: scenario.EngineeringManagerAlias
						? scenario.EngineeringManagerAlias.toLowerCase()
						: '',
					EngineeringManager: scenario.EngineeringManager
						? scenario.EngineeringManager
						: '',
					Id: id,
					IsExecutive: scenario.IsExecutive,
					Name: scenario.Name,
					Order: 9999,
					OwnerAlias: scenario.Owner ? scenario.Owner : '',
					Owner: scenario.Owner ? scenario.Owner : '',
					Parent1Id: Number(scenario.Parent1Id),
					Parent1Name: scenario.Parent1Name,
					Parent2Id: 'Unspecified',
					Parent2Name: 'Unspecified',
					SegmentOwner: scenario.SegmentOwner ? scenario.SegmentOwner : '',
					Source: 'nonsll',
					CreatedAt: scenario.CreatedAt
						? scenario.CreatedAt.slice(0, 10).replace(/-/g, '')
						: '',
					ScenarioID: id,
				});

				scenarioGroupMap['Unspecified'] = !scenarioGroupMap['Unspecified']
					? 1
					: scenarioGroupMap['Unspecified'] + 1;
				if (!scenarioGroupScenarioMap['Unspecified'])
					scenarioGroupScenarioMap['Unspecified'] = [id];
				else scenarioGroupScenarioMap['Unspecified'].push(id);
			}
		}
	}
	//inactive scenarios will never have data in coreScenarioData
	activeMinorScenarioList = activeMinorScenarioList.concat(Object.values(unspecifiedScenarios));

	majorScenarioList.push({
		L4ManagerAlias: '',
		L4ManagerName: '',
		ParentScenarioID: 0,
		ParentScenarioName: 'Unspecified',
		ScenarioGroup: 'Unspecified',
		ScenarioName: 'Unspecified',
		Id: 44,
		IsExecutive: false,
		Name: 'Unspecified',
		Order: 99999,
		Parent1Id: 0,
		Parent1Name: 'Unspecified',
		Parent2Id: null,
		Parent2Name: 'Unspecified',
		EngineeringManager: '',
		EngineeringManagerAlias: '',
		ScenarioID: 44,
	});

	const scenarioGroupList: ScenarioGroupMetadata[] = Object.entries(scenarioGroupMap)
		.reduce((acc: ScenarioGroupMetadata[], [key, val]) => {
			const sg: ScenarioGroupMetadata = {
				Id: key.toLowerCase(),
				Name: key,
				Total: val,
				Order: 0,
				ScenarioIds: scenarioGroupScenarioMap[key],
			};
			acc.push(sg);
			return acc;
		}, [])
		.sort((a, b) => {
			if (a.Order === b.Order) return a.Id > b.Id ? 1 : -1;
			return a.Order >= b.Order ? 1 : -1;
		});

	return {
		scenarioGroupList: scenarioGroupList,
		majorScenarioList: majorScenarioList,
		activeMinorScenarioList: activeMinorScenarioList,
		fullMinorScenarioList: activeMinorScenarioList
			.concat(inactiveMinorScenarioList)
			.filter((i) => !(i.Parent2Name === 'Unspecified' && i.Parent1Name === 'Unspecified')),
		// metaDataStatus: 'success' as Status,
	};
}

function transformPeopleMetadata(people: PeopleMetadata[]): Record<string, PeopleMetadata> {
	const peopleFiltered = people
		.filter((person) => person.Name !== '' && person.Name !== null && person.Name !== 'ALL')
		.sort((a, b) => (a.Name > b.Name ? 1 : -1));
	const peopleMap: Record<string, PeopleMetadata> = {};
	// if (owners instanceof Array && owners.length > 0 && owners[0] instanceof Object) {
	peopleFiltered.forEach((person) => (peopleMap[person.Alias.toLowerCase()] = person));
	// }
	return peopleMap;
}

function transformGraduatedScenarios(
	grauatedScenarios: GraduatedScenario[]
): ModifiedGraduatedScenario[] {
	const gScenarios: ModifiedGraduatedScenario[] = [];
	for (const item of grauatedScenarios) {
		gScenarios.push({
			CustomerId: Number(item.CustomerID) as CustomerId,
			ScenarioId: Number(item.ScenarioID),
			convertedDate: item.GraduatedDetails.GraduatedDate.slice(0, 10).replace(/-/g, ''),
			fullGraduatedDate: new Date(item.GraduatedDetails.GraduatedDate),
			displayGraduatedDate: VF.monthYearDate(new Date(item.GraduatedDetails.GraduatedDate)),
			GraduatedDetails: item.GraduatedDetails,
		});
	}

	return gScenarios;
}

function appendOwnerToManager(
	emMetaData: Record<string, PeopleMetadata>,
	fullMinorScenarioList: MinorScenarioMetaData[]
) {
	const newEmMetaData: Record<string, PeopleMetadata> = { ...emMetaData };
	for (const minorSc of fullMinorScenarioList) {
		// edge case there are scenario with empty EngineeringManagerAlias
		if (!minorSc.EngineeringManagerAlias || !newEmMetaData[minorSc.EngineeringManagerAlias]) {
			//continue;
			if (emMetaData[minorSc.OwnerAlias.toLowerCase()]) {
				minorSc.EngineeringManagerAlias = minorSc.OwnerAlias.toLowerCase();
				minorSc.EngineeringManager = emMetaData[minorSc.Owner.toLowerCase()]?.Name;
			} else {
				continue;
			}
		}

		if (!newEmMetaData[minorSc.EngineeringManagerAlias].Owners) {
			// Initialize the owner set under the manager
			newEmMetaData[minorSc.EngineeringManagerAlias].Owners = new Set();
		}
		minorSc.OwnerAlias &&
			newEmMetaData[minorSc.EngineeringManagerAlias].Owners.add(
				minorSc.OwnerAlias.toLowerCase()
			);
	}
	return newEmMetaData;
}

function CoreDataProvider({ children }: React.PropsWithChildren<unknown>): JSX.Element {

	const [error, setError] = useState<string[] | null>(null);
	const coreDataReducer = useCallback((prevState: CoreDataState, action: Action): CoreDataState => {
		let newState: CoreDataState;
		switch (action.type) {
			case CoreDataActions.ApiError: {
				if (error === null) {
					setError([action.error]);
				}
				else if (!error.includes(action.error)) {
					setError(error.concat(action.error));
				}

				return { ...prevState, scenarioStatus: 'error' };
			}
			case CoreDataActions.LoadCoreData: {
				loadCoreData(action.dispatch, action.apiParams, action.getFullScenarios);
				if (prevState.metaDataStatus === 'empty')
					loadMetaData(action.dispatch, action.getFullScenarios);
				return {
					...prevState,
					scenarioStatus: 'fetching',
					coreScenarioData: {},
					activeMinorScenarioList: [],
					fullMinorScenarioList: [],
					majorScenarioList: [],
					scenarioGroupList: [],
					metaDataStatus: 'fetching',
					managementMetadataStatus: action.getFullScenarios
						? 'fetching'
						: prevState.managementMetadataStatus,
				};
			}
			// case CoreDataActions.LoadAllMetaData: {
			// 	loadMetaData(action.dispatch);
			// 	return { ...prevState }
			// }
			case CoreDataActions.FetchedScenarios: {
				return {
					...prevState,
					coreScenarioData: action.scenarioData,
					// scenarioStatus: 'success',
				};
			}
			case CoreDataActions.FetchedScenariosMetaData: {
				newState = { ...prevState, scenarioMetaData: action.scenarioMetaData };
				// newState.metaDataStatus = checkMetaDataStatus(newState);
				return newState;
			}
			case CoreDataActions.FetchedScenarioGroupTypeData: {
				newState = { ...prevState, scenarioGroupTypeData: action.scenarioGroupTypeData };
				return newState;
			}
			case CoreDataActions.FetchedSelfServeScenariosMetaData: {
				newState = {
					...prevState,
					selfServeScenarioMetaData: action.selfServeScenarioMetaData,
				};
				// newState.metaDataStatus = checkMetaDataStatus(newState);
				return newState;
			}
			case CoreDataActions.FetchedEMMetaData: {
				newState = { ...prevState, emMetaData: transformPeopleMetadata(action.emMetaData) };
				// newState.metaDataStatus = checkMetaDataStatus(newState);

				return newState;
			}
			case CoreDataActions.FetchedOwnersMetaData: {
				newState = {
					...prevState,
					ownersMetaData: transformPeopleMetadata(action.ownersMetaData),
				};
				// newState.metaDataStatus = checkMetaDataStatus(newState);
				return newState;
			}
			case CoreDataActions.GenerateMetaDataLists: {
				const transformed = transformScenariosList(
					prevState.scenarioMetaData,
					prevState.coreScenarioData
				);
				if (!transformed) return prevState;
				newState = {
					...prevState,
					...transformed,
					scenarioStatus: 'success',
					managementMetadataStatus: action.getFullScenarios
						? 'success'
						: prevState.managementMetadataStatus,
				};
				return newState;
			}
			case CoreDataActions.FetchedUserMetaData: {
				const userMetaData = {
					...prevState.userMetadata,
					...action.userMetadata,
				};
				newState = {
					...prevState,
					userMetadata: userMetaData,
				};
				return newState;
			}
			case CoreDataActions.FetchedUserProfileData: {
				newState = {
					...prevState,
					userMetadata: {
						...prevState.userMetadata,
						ProfileImage: action.profileImage,
					},
				};
				return newState;
			}
			case CoreDataActions.FetchedUserSecurityGroup: {
				newState = {
					...prevState,
					userMetadata: {
						...prevState.userMetadata,
						CanApprovePendingScenarios: action.canApproveScenarios,
					},
				};
				return newState;
			}
			case CoreDataActions.LoadScenarioMetadata: {
				loadScenarioMetadata(action.dispatch, action.getFullScenarios);
				return {
					...prevState,
					// activeMinorScenarioList: [],
					// fullMinorScenarioList: [],
					// majorScenarioList: [],
					// scenarioGroupList: [],
					metaDataStatus: 'fetching',
					managementMetadataStatus: action.getFullScenarios
						? 'fetching'
						: prevState.managementMetadataStatus,
				};
			}
			case CoreDataActions.LoadSelfServeScenarioMetadata: {
				loadSelfServeScenariosMetaData(action.dispatch, action.getFullScenarios);
				return {
					...prevState,
					metaDataStatus: 'fetching',
					managementMetadataStatus: action.getFullScenarios
						? 'fetching'
						: prevState.managementMetadataStatus,
				};
			}
			case CoreDataActions.LoadManagementMetadata: {
				return prevState;
			}
			case CoreDataActions.CheckMetaDataStatus: {
				return { ...prevState, metaDataStatus: checkMetaDataStatus(prevState) };
			}
			case CoreDataActions.MapOwnersToEMs: {
				return {
					...prevState,
					emMetaData: appendOwnerToManager(
						prevState.emMetaData,
						prevState.fullMinorScenarioList
					),
				};
			}
			case CoreDataActions.FetchedGraduatedScenarios: {
				return {
					...prevState,
					graduatedScenarios: action.graduatedScenarios,
				};
			}
			default: {
				throw new Error(`Unhandled action: ${ action.type }`);
			}
		}
	}, [error]);

	const [mappedOwnersToEM, setMappedOwnersToEm] = useState<boolean>(false);
	const [state, dispatch] = useReducer(coreDataReducer, initialState);
	const { emMetaData, ownersMetaData, scenarioMetaData, selfServeScenarioMetaData } = state;

	// useEffect(() => {
	// 	if (state.metaDataStatus === 'empty') {
	// 		dispatch({ type: CoreDataActions.LoadAllMetaData, dispatch: dispatch })
	// 	}
	// }, [])

	useEffect(() => {
		dispatch({ type: CoreDataActions.CheckMetaDataStatus });
	}, [emMetaData, ownersMetaData, scenarioMetaData, selfServeScenarioMetaData]);

	useEffect(() => {
		if (
			!mappedOwnersToEM &&
			!isEmpty(emMetaData) &&
			state.activeMinorScenarioList.length !== 0
		) {
			setMappedOwnersToEm(true);
			dispatch({ type: CoreDataActions.MapOwnersToEMs });
		}
	}, [emMetaData, mappedOwnersToEM, state.activeMinorScenarioList.length]);

	return (
		<DataProviderContext.Provider value={ state }>
			{ error &&
				<Dialog
					hidden={ error === null }
					dialogContentProps={ {
						type: DialogType.normal,
						title: 'API Data Retrieval Error : CoreDataContext',
					} }
					onDismiss={ () => setError(null) }
				>
					<DialogContent>
						{ error.map((err, index) => <p key={ index }>{ err }</p>) }
					</DialogContent>
					<DialogFooter>
						<PrimaryButton onClick={ () => setError(null) } text="Close" />
					</DialogFooter>
				</Dialog> }
			<DataProviderDispatch.Provider value={ dispatch }>
				{ children }
			</DataProviderDispatch.Provider>
		</DataProviderContext.Provider>
	);
}

// Custom hooks

function useCoreDataContext(): CoreDataState {
	const context = React.useContext(DataProviderContext);
	if (context === undefined) {
		throw new Error('useDataProviderState must be used within a CoreDataProvider ');
	}
	return context;
}

function useCoreDataDispatch(): CoreDataDispatch {
	const context = React.useContext(DataProviderDispatch);
	if (context === undefined) {
		throw new Error('useDataProviderDispatch must be used within a CoreDataProvider ');
	}

	return context;
}

function useCoreDataProvider() {
	return {
		coreData: useCoreDataContext(),
		coreDispatch: useCoreDataDispatch(),
	};
}

CoreDataProvider.useCoreDataProvider = useCoreDataProvider;

export { CoreDataProvider, useCoreDataContext, useCoreDataDispatch };

